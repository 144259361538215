import React, {useState} from 'react'
import {CartSectionDetail} from '../ui/payment-information'
import {ParagraphText, Icon} from '@thryvlabs/maverick'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {formatPrice} from '../../utils/currency-format'

const CENTER_PLANS = ['command center', 'business center', 'marketing center']

function CartDetail({
  open,
  title,
  className,
  total,
  items,
  selectedPlan,
  calculatedTaxes = null,
  coupons,
  country,
  selectedPlanCodes,
  is5Order,
  supportFee,
  paymentPreview,
  paymentState,
}) {
  const [taxesAndFeesOpen, setTaxesAndFeesOpen] = useState(true)
  const {frontendPreviewPayment} = useFlags()

  const isMonthly = title === 'Monthly'
  const isAusNZ = ['AU', 'NZ'].includes(country)
  const hideTaxes =
    !isAusNZ &&
    (!calculatedTaxes || isMonthly) &&
    (!frontendPreviewPayment || !paymentPreview)
  const displayTaxAndFees = !isAusNZ
  async function replaceCoupons() {
    const transitionDisplay = {
      name: 'Transition Incentive',
      isValid: true,
      description: 'Transition Incentive',
      type: 'percent',
      isPercentDiscount: true,
      discountPercent: 75,
      code: 'transition75',
      appliesToAllPlans: false,
      appliesToPlans: [
        'professional-4-dexyp-m2m',
        'unlimited-4-dexyp-m2m',
        'plus-4-dexyp-m2m',
        'unlimited-4-barbados-m2m',
        'professional-4-barbados-m2m',
        'plus-4-barbados-m2m',
        'plus-4-caymanislands-m2m',
        'professional-4-caymanislands-m2m',
        'unlimited-4-caymanislands-m2m',
        'addon-4-mc-m2m',
        'cc_plus-5-thryv-m2m',
        'cc_pro-5-thryv-m2m',
        'bc_plus-5-thryv-m2m',
        'bc_pro-5-thryv-m2m',
        'bc_unlm-5-thryv-m2m',
        'mc_pro-5-thryv-m2m',
        'mc_plus-5-thryv-m2m',
      ],
    }
    if (
      coupons.some(
        x =>
          x.code === '25month1' ||
          x.code === '25month2' ||
          x.code === '25month3',
      ) &&
      title === 'Today'
    ) {
      coupons = coupons.slice(0, coupons.length - 3)
      coupons.push(transitionDisplay)
      items = items.flatMap(current => {
        const currentPrice = Number(current.currency.unitAmount)
        const shouldApplyIncentive =
          transitionDisplay.appliesToPlans.includes(current?.code) &&
          title === 'Today' &&
          currentPrice > 0

        if (!shouldApplyIncentive) {
          return current
        }

        const incentive = {
          name: 'Transition Incentive',
          currency: {
            unitAmount:
              !frontendPreviewPayment || !paymentPreview
                ? current.currency.unitAmount * 0.75
                : current.discount,
          },
          isCoupon: true,
        }

        if (!is5Order) {
          return [current, incentive]
        }

        return [current, ...(current.transitionApplies ? [incentive] : [])]
      })
    } else if (coupons.find(coupon => coupon.code === 'transition75')) {
      items = items.flatMap(current => {
        const currentPrice = Number(current.currency.unitAmount)
        if (
          transitionDisplay.appliesToPlans.includes(current?.code) &&
          title === 'Today' &&
          currentPrice > 0
        ) {
          const transitionDiscount = {
            name: 'Transition Incentive',
            currency: {
              unitAmount:
                !frontendPreviewPayment || !paymentPreview
                  ? current.currency.unitAmount * 0.75
                  : current.discount,
            },
            isCoupon: true,
          }
          return [
            current,
            ...(transitionDiscount.currency.unitAmount > 0
              ? [transitionDiscount]
              : []),
          ].filter(Boolean)
        }
        return current
      })
    } else if (coupons.find(coupon => coupon.code === 'nci')) {
      const oneMonthFreeDisplay = {
        name: 'New Customer Incentive',
        isValid: true,
        description: 'One Month Free',
        type: 'percent',
        duration: 'single_use',
        durationUnit: null,
        durationAmount: null,
        appliesToAllPlans: false,
        appliesToPlans: [
          'previewtax',
          'cc_plus-5-thryv-m2m',
          'cc_pro-5-thryv-m2m',
          'bc_plus-5-thryv-m2m',
          'bc_pro-5-thryv-m2m',
          'bc_unlm-5-thryv-m2m',
          'mc_pro-5-thryv-m2m',
          'mc_plus-5-thryv-m2m',
        ],
        isPercentDiscount: true,
        discountAmount: 0,
        discountPercent: 100,
        code: 'nci',
      }
      items = items.flatMap(current => {
        if (
          oneMonthFreeDisplay.appliesToPlans.includes(current?.code) &&
          title === 'Today'
        ) {
          return [
            current,
            {
              name: 'One Month Free',
              currency: {unitAmount: current.currency.unitAmount},
              isCoupon: true,
            },
          ]
        }
        return current
      })
    }
  }
  if (
    coupons.some(
      x =>
        x.code === '25month1' ||
        x.code === '25month2' ||
        x.code === '25month3' ||
        x.code === 'transition75' ||
        x.code === 'nci',
    )
  ) {
    replaceCoupons()
  }
  let couponDiscountAmount = 0
  let totalDiscount = 0
  return (
    <div className={className}>
      <CartSectionDetail
        section={true}
        title={`Due ${title}`}
        defaultState={open}
      >
        <div className="pt-0 pb-2 pl-4 pr-4">
          {selectedPlan && (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" className="font-weight-bold">
                {selectedPlan.planName}
              </ParagraphText>
              <ParagraphText variant="reg" className="font-weight-bold">
                {formatPrice(selectedPlan.currency.amount)}
              </ParagraphText>
            </div>
          )}
          {items.map((item, index) => {
            const nameLower = item.name.toLowerCase()
            const isCenter =
              is5Order && CENTER_PLANS.some(plan => nameLower.includes(plan))
            const isSEOOrTL =
              nameLower.includes('seo') || nameLower.includes('thryv leads')
            const isToday = title === 'Today'
            const itemQuantity =
              isToday && paymentPreview && isSEOOrTL ? 1 : item.quantity || 1
            return (
              <div
                key={index}
                className="d-flex justify-content-between"
                style={{marginBottom: '1rem'}}
              >
                <div
                  className="d-flex align-items-center"
                  style={{columnGap: '5px'}}
                >
                  {isCenter && (
                    <img
                      style={{borderRadius: '5px'}}
                      src={item.img}
                      alt={item.plan}
                      height="24px"
                      width="24px"
                    />
                  )}
                  <ParagraphText
                    variant="reg"
                    className={`${!is5Order && 'font-weight-bold'} m-0`}
                    style={{color: is5Order ? '#4D4D4D' : ''}}
                  >
                    {item.name}
                    {item.quantity && item.quantity > 1 && !isSEOOrTL
                      ? ` (${item.quantity}x)`
                      : ''}
                  </ParagraphText>
                </div>
                <ParagraphText
                  variant="reg"
                  style={{color: is5Order ? '#4D4D4D' : ''}}
                  className={`${!is5Order && 'font-weight-bold'} m-0`}
                >
                  {item.isCoupon && '-'}
                  {formatPrice(item.currency.unitAmount, itemQuantity)}
                </ParagraphText>
              </div>
            )
          })}
          {title === 'Today' &&
            coupons.map((coupon, index) => {
              couponDiscountAmount = 0

              if (coupon.discountAmount) {
                couponDiscountAmount += coupon.discountAmount
              } else if (coupon.isPercentDiscount && coupon.appliesToAllPlans) {
                selectedPlanCodes.forEach(addon => {
                  if (!addon.code.includes('setupfee')) {
                    couponDiscountAmount +=
                      addon.currency.unitAmount *
                      addon.quantity *
                      (coupon.discountPercent / 100)
                  }
                })
              } else {
                selectedPlanCodes.forEach(addon => {
                  const appliesToPlan = coupon?.appliesToPlans?.includes(
                    addon.code,
                  )
                  const notSetupFee = !addon.name?.includes('Setup Fee')
                  const isTransitionIncentive = coupon.code === 'transition75'

                  if (appliesToPlan && notSetupFee) {
                    if (
                      !isTransitionIncentive ||
                      (isTransitionIncentive &&
                        (!is5Order || (is5Order && addon?.transitionApplies)))
                    ) {
                      couponDiscountAmount +=
                        addon.currency.unitAmount *
                        (coupon.discountPercent / 100)
                    }
                  }
                })
              }
              totalDiscount += couponDiscountAmount
              if (coupon.code === 'transition75' || coupon.code === 'nci') {
                return <></>
              }
              return (
                couponDiscountAmount && (
                  <div key={index} className="d-flex justify-content-between">
                    <ParagraphText
                      variant="reg"
                      className={`${!is5Order && 'font-weight-bold'}`}
                      style={{color: is5Order ? '#4D4D4D' : ''}}
                    >
                      {coupon.description || coupon.name}
                    </ParagraphText>
                    <ParagraphText
                      variant="reg"
                      className={`${!is5Order && 'font-weight-bold'}`}
                      style={{color: is5Order ? '#4D4D4D' : ''}}
                    >
                      -{formatPrice(couponDiscountAmount)}
                    </ParagraphText>
                  </div>
                )
              )
            })}
          {paymentState?.paymentInfo?.balanceInfo?.amount && (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                Credit
              </ParagraphText>
              <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                {formatPrice(
                  paymentState?.paymentInfo?.balanceInfo?.amount || 0,
                )}
              </ParagraphText>
            </div>
          )}
          {displayTaxAndFees && (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" className="font-weight-bold">
                Tax & Fees
              </ParagraphText>

              {calculatedTaxes !== null && title !== 'Monthly' ? (
                <>
                  <button
                    className="w-full"
                    onClick={() => setTaxesAndFeesOpen(prevVal => !prevVal)}
                    aria-describedby={`Click to access the charges due today below.`}
                  >
                    <div
                      className={`flex row flex-row-reverse justify-content-between cursor-pointer border-thryv-gray-light-400`}
                    >
                      <div className="flex flex-row-reverse text-center gap-4">
                        <Icon
                          type={'solid'}
                          variant={`caretDown`}
                          className={`mr-2 fill-current text-thryv-black-500 -rotate-90 ${
                            taxesAndFeesOpen && 'rotate-0'
                          } transition-all duration-300 ease-in-out cursor-pointer`}
                          height={'10'}
                          width={'10'}
                        />
                        <ParagraphText
                          variant="reg"
                          className="font-weight-bold"
                        >
                          {' '}
                        </ParagraphText>
                      </div>
                    </div>
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="w-full"
                    onClick={() => setTaxesAndFeesOpen(prevVal => !prevVal)}
                    aria-describedby={`Click to access the charges due today below.`}
                  >
                    <div
                      className={`flex row flex-row-reverse justify-content-between cursor-pointer border-thryv-gray-light-400`}
                    >
                      <div className="flex flex-row-reverse text-center gap-4">
                        <Icon
                          type={'solid'}
                          variant={`caretDown`}
                          className={`mr-2 fill-current text-thryv-black-500 -rotate-90 ${
                            taxesAndFeesOpen && 'rotate-0'
                          } transition-all duration-300 ease-in-out cursor-pointer`}
                          height={'10'}
                          width={'10'}
                        />
                        <ParagraphText
                          variant="reg"
                          className="font-weight-bold"
                        >
                          {' '}
                        </ParagraphText>
                      </div>
                    </div>
                  </button>
                </>
              )}
            </div>
          )}
          {isAusNZ && supportFee && (
            <div className="d-flex justify-content-between">
              <ParagraphText variant="reg" className="font-weight-bold">
                Fees
              </ParagraphText>

              <button
                className="w-full"
                onClick={() => setTaxesAndFeesOpen(prevVal => !prevVal)}
                aria-describedby={`Click to access the charges due today below.`}
              >
                <div
                  className={`flex row flex-row-reverse justify-content-between cursor-pointer border-thryv-gray-light-400`}
                >
                  <div className="flex flex-row-reverse text-center gap-4">
                    <Icon
                      type={'solid'}
                      variant={`caretDown`}
                      className={`mr-2 fill-current text-thryv-black-500 -rotate-90 ${
                        taxesAndFeesOpen && 'rotate-0'
                      } transition-all duration-300 ease-in-out cursor-pointer`}
                      height={'10'}
                      width={'10'}
                    />
                    <h6
                      className={`text-accordion-title font-montserrat font-semibold text-thryv-black-500
                        text-card-title-h7 font-montserrat font-semibold text-thryv-black-500`}
                    >
                      {' '}
                    </h6>
                  </div>
                </div>
              </button>
            </div>
          )}

          <div
            className={`${
              !taxesAndFeesOpen
                ? 'hidden opacity-0 scale-0 transition-all duration-500 ease-in-out '
                : 'visible opacity-100 scale-100 transition-all duration-700 ease-in-out '
            }`}
          >
            {displayTaxAndFees && (
              <div className="d-flex justify-content-between">
                <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                  Tax
                </ParagraphText>
                {title !== 'Monthly' && (
                  <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                    {calculatedTaxes !== null
                      ? formatPrice(calculatedTaxes)
                      : 'To be calculated'}
                  </ParagraphText>
                )}
                {isMonthly && (
                  <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                    To be calculated
                  </ParagraphText>
                )}
              </div>
            )}
            {(displayTaxAndFees || (isAusNZ && supportFee)) && (
              <div className="d-flex justify-content-between">
                <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                  {supportFee ? '24/7 Service & Support Fee' : 'Fees'}
                </ParagraphText>
                <ParagraphText variant="reg" style={{color: '#4D4D4D'}}>
                  {supportFee
                    ? `$${parseFloat(supportFee.currency.unitAmount).toFixed(
                        2,
                      )}`
                    : '$0.00'}
                </ParagraphText>
              </div>
            )}
          </div>
        </div>
      </CartSectionDetail>
      <div className="total__calculation d-flex justify-content-between">
        <ParagraphText
          variant="lg"
          style={{fontSize: '14px'}}
          className="font-weight-bold"
        >
          Total Due {title}
        </ParagraphText>
        <ParagraphText
          variant="lg"
          style={{fontSize: '14px'}}
          className="font-weight-bold"
        >
          {calculatedTaxes > 0 &&
            (!frontendPreviewPayment || !paymentPreview) &&
            !isAusNZ &&
            title !== 'Monthly' &&
            formatPrice(
              Math.max(
                total +
                  calculatedTaxes -
                  totalDiscount +
                  (paymentState?.paymentInfo?.balanceInfo?.amount || 0),
                0,
              ),
            )}
          {hideTaxes &&
            `${formatPrice(
              Math.max(
                total -
                  totalDiscount +
                  (paymentState?.paymentInfo?.balanceInfo?.amount || 0),
                0,
              ),
            )} + tax*`}
          {isAusNZ &&
            (!frontendPreviewPayment || !paymentPreview) &&
            `$${parseFloat(
              Math.max(
                total -
                  totalDiscount +
                  (paymentState?.paymentInfo?.balanceInfo?.amount || 0),
                0,
              ),
            ).toFixed(2)}`}
          {!isMonthly &&
            frontendPreviewPayment &&
            paymentPreview &&
            formatPrice(
              Math.max(
                paymentPreview.total +
                  (paymentState?.paymentInfo?.balanceInfo?.amount || 0),
                0,
              ),
            )}
        </ParagraphText>
      </div>
    </div>
  )
}

export {CartDetail}
