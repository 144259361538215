import React from 'react'
import {ProductItem} from './product-item'
import {ParagraphText} from '@thryvlabs/maverick'
import checkIfIncentiveApplies from '../../utils/check-if-incentive-applies'
import {currencyFormat} from '../../utils/currency-format'
import {BC_PLANS, MC_PLANS} from '../../utils/constants'

function Container({children, isFragment}) {
  if (isFragment) {
    return <>{children}</>
  }
  return (
    <div
      style={{
        border: '1px solid rgba(0, 0, 0, 0.2)',
        borderRadius: '3px',
        paddingLeft: '10px',
        paddingTop: '3px',
        lineHeight: '10px',
      }}
    >
      {children}
    </div>
  )
}

function ProductSection({
  title,
  products,
  isOld = false,
  isUpgrade,
  eligibleTransitionCenters,
  incentiveCoupon,
  upgradePaymentPreview,
  country,
  orderCouponCodes,
  selectedCentersAndApps,
  isCohort,
}) {
  const isOneTimeFee = title === 'One Time Fees'

  // Display MC pro enhanced with the incentive applied
  const formattedProducts = products.map(product => {
    if (product.code === 'mc_pro_enh-5-thryv-m2m') {
      const couponAmount =
        orderCouponCodes?.find(coupon =>
          coupon.appliesToPlans?.includes(product.code),
        )?.discountAmount || 0
      const price = product.amount - couponAmount
      return {
        ...product,
        amount: currencyFormat(price, country, true),
      }
    }
    return product
  })

  return (
    <Container isFragment={!isOneTimeFee}>
      <ParagraphText variant="lg" className="font-weight-bold">
        {title}
      </ParagraphText>
      <div className={!isOneTimeFee ? 'ml-2' : ''}>
        {formattedProducts.map(currentItem => {
          const showIncentive =
            !isOld &&
            title === 'Centers' &&
            checkIfIncentiveApplies(
              currentItem,
              isUpgrade,
              eligibleTransitionCenters,
              incentiveCoupon,
            )
          const {code} = currentItem || {}
          const isListingsApp = code === 'app-listings'
          const isBCorMCInCart = selectedCentersAndApps?.centers.some(
            ({code}) => BC_PLANS.includes(code) || MC_PLANS.includes(code),
          )
          return (
            <ProductItem
              key={!isOld ? currentItem.id : currentItem.id + 'old'}
              currentItem={currentItem}
              showIncentive={showIncentive}
              isOneTimeFee={isOneTimeFee}
              upgradePaymentPreview={upgradePaymentPreview}
              incentiveCoupon={incentiveCoupon}
              country={country}
              crossedOut={isListingsApp && isBCorMCInCart}
              isCohort={isCohort}
              orderCouponCodes={orderCouponCodes}
            />
          )
        })}
      </div>
    </Container>
  )
}

export {ProductSection}
