import React, {useEffect, useRef, useState} from 'react'
import {ParagraphText, Checkbox, Button, Input} from '@thryvlabs/maverick'
import {CatalogSectionContainer} from '../ui/command-center-layout'
import {SEOValidation} from './seo-validation'
import {DomainValidation} from './domain-validation'
import {ThryvLeadsValidation} from './thryv-leads-validation'
import {WebsiteSettingsModal} from './website-settings-modal'
import {SliderRange} from '../slider-range'
import {toast} from 'react-toastify'
import {EaidValidation} from './eaid-validation'
import dayjs from 'dayjs'
import {formatPrice} from '../../utils/currency-format'

const ITEM_CONFIG = {
  '5 Extra Website Pages': {
    max: 250,
    range: 5,
    hasSlider: true,
  },
  'Professional Designed Logo': {
    max: 100,
    range: 1,
    hasInput: true,
  },
}

const checkIfAdded = (options, itemName, optionsType) => {
  if (optionsType === 'ent') {
    return options.some(({entitlements}) =>
      entitlements?.some(({name}) => name === itemName),
    )
  }
  if (optionsType === 'app') {
    return options.some(({name}) => name === itemName)
  }
}

function Options({
  selectedCountry,
  selectedPlan,
  setSelectedPlan,
  selectedCentersAndApps,
  setSelectedCentersAndApps,
  selectedOptions,
  setSelectedOptions,
  proposalId,
  setProposalId,
  proposalValidation,
  setProposalValidation,
  mcDomainConfig,
  setMcDomainConfig,
  thryvLeadsId,
  setThryvLeadsId,
  thryvLeadsValidation,
  setThryvLeadsValidation,
  futureBillingDate,
  setFutureBillingDate,
  sensisSiteDomain,
  setSensisSiteDomain,
  quantity,
  setQuantity,
  seatQty,
  oneTimeFees,
  centers,
  isSEOMBGDisabled,
  setIsSEOMBGDisabled,
  defaultCentersAndApps,
  seoDomainConfig,
  setSeoDomainConfig,
  isWebsiteSettingsModalVisible,
  setIsWebsiteSettingsModalVisible,
  setIsEaidFound,
  isEaidFound,
  cohortEaid,
  setCohortEaid,
  upgradePaymentPreview,
  setUpgradePaymentPreview,
  setAllowInvoice,
  isLegacyUpgradeAllowed,
  setIsLegacyUpgradeAllowed,
  thryvId,
  setIsCohort,
}) {
  const [isContinueDisableLogo, setIsContinueDisableLogo] = useState(false)
  const [planPrice, setPlanPrice] = useState('$0.00')

  const isSEO = selectedPlan?.name.toLowerCase().includes('seo')
  const isMarketingCenter = selectedPlan?.name
    .toLowerCase()
    .includes('marketing')
  const recommendedSEOPrice = useRef()
  const isThryvLeads = selectedPlan?.name === 'Thryv Leads'
  const isMCProEnhanced = selectedPlan?.code === 'mc_pro_enh-5-thryv-m2m'
  const isContinueDisabledForSEO =
    isSEO &&
    (!proposalValidation?.isValidProposal ||
      (!seoDomainConfig.isValid && !isMCProEnhanced))
  const isContinueDisabledForMC =
    isMarketingCenter &&
    (!mcDomainConfig.isValid ||
      (selectedPlan.name === 'Marketing Center Pro Enhanced' &&
        !isLegacyUpgradeAllowed))
  const isContinueDisabledForThryvLeads =
    isThryvLeads && !thryvLeadsValidation?.passed
  const itemConfig = ITEM_CONFIG[selectedPlan?.name]
  const isCanAusNz = ['CA', 'AU', 'NZ'].includes(selectedCountry)
  const isOnboardingApplicable = !defaultCentersAndApps.centers.some(
    center => center.code.includes('bc') || center.code.includes('mc'),
  )

  const convertSeoNg =
    selectedPlan?.code === 'seombg-5-thryv-m2m' &&
    proposalValidation?.price < recommendedSEOPrice.current
  const isWebsiteAppAlreadyIncluded = defaultCentersAndApps?.apps?.some(
    ({code}) => code === 'addon-5-website_difm',
  )
  const wasWebsiteAppAddedToCart = selectedCentersAndApps?.apps?.some(
    ({code, alreadyIncluded}) =>
      code === 'addon-5-website_difm' && !alreadyIncluded,
  )
  const planWebsiteEnt = selectedPlan?.entitlements?.find(
    ({code}) => code === 'ent-5-website',
  )
  const isWebsiteFeeNotApplicable =
    isWebsiteAppAlreadyIncluded && planWebsiteEnt
  const isWebsiteEntAlreadyInOrder = checkIfAdded(
    selectedCentersAndApps?.centers,
    'Professionally Designed Website',
    'ent',
  )

  const handleSelectOption = option => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter(o => o !== option))
    } else {
      if (option.name === 'Professionally Designed Website') {
        setIsWebsiteSettingsModalVisible(true)
      }
      setSelectedOptions([...selectedOptions, option])
    }
  }

  const handleAddToOrder = () => {
    const requiredOptions =
      selectedPlan.entitlements?.filter(
        entitlement =>
          entitlement.isRequired &&
          !selectedCentersAndApps.centers.some(({entitlements}) =>
            entitlements?.some(
              ({name, alreadyIncluded}) =>
                name === entitlement.name && alreadyIncluded,
            ),
          ),
      ) || []
    const centerType =
      selectedPlan.planType === 'center' && selectedPlan.name.split(' ')[0]
    const isCommandCenter = selectedPlan?.name
      .toLowerCase()
      .includes('command center')
    const isCCPro = selectedPlan?.name.toLowerCase().includes('pro')
    const ccSeatsCode = isCCPro ? 'addon-5-seats_ccpro' : 'addon-5-seats_ccplus'
    const isWebsiteApp =
      selectedPlan?.name === 'Professionally Designed Website'
    const includesWebsite = selectedOptions.some(
      option => option.name === 'Professionally Designed Website',
    )
    const includesLogo = selectedPlan.name === 'Professional Designed Logo'
    const isSignaturesPaidInCart = selectedCentersAndApps.apps.some(
      ({code}) => code === 'addon-5-signatures',
    )
    let selectedPlanWithOptions = {
      ...selectedPlan,
      entitlements: [...selectedOptions, ...requiredOptions],
      quantity,
    }
    if (convertSeoNg) {
      toast.warning('Attn: SEO is no longer guaranteed.')
      const {
        amount,
        img,
        entitlements,
        id,
        excludes,
        requiresCenter,
        code,
        name,
      } = centers.find(({code}) => code === 'seo-5-thryv-m2m')
      selectedPlanWithOptions = {
        ...selectedPlanWithOptions,
        name,
        amount,
        img,
        entitlements,
        id,
        excludes,
        requiresCenter,
        code,
      }
    }

    selectedPlanWithOptions = {
      ...selectedPlanWithOptions,
      ...(includesWebsite &&
        sensisSiteDomain && {
          entitlements: selectedOptions.map(option =>
            option.name === 'Professionally Designed Website'
              ? {
                  ...option,
                  sensisSiteDomain,
                  applicableOneTimes: option.applicableOneTimes,
                }
              : option,
          ),
        }),
      ...(isWebsiteApp && sensisSiteDomain && {sensisSiteDomain}),
      ...(isSEO && {
        seoProposalId: proposalId,
        amount: proposalValidation.price,
        quantity: proposalValidation?.price,
        ...(futureBillingDate && {
          startDate: futureBillingDate,
        }),
        seoDomain: seoDomainConfig.value,
      }),
      ...(isThryvLeads && {
        thryvLeadsId,
        amount: thryvLeadsValidation.budget,
        ...(futureBillingDate && {
          startDate: futureBillingDate,
        }),
        quantity: thryvLeadsValidation.budget,
      }),
      ...(isMarketingCenter && {mcDomain: mcDomainConfig.value}),
      ...(isCommandCenter && {
        entitlements: [
          {
            code: ccSeatsCode,
            quantity: seatQty,
          },
        ],
      }),
      ...(includesLogo && {
        quantity: quantity,
      }),
      ...(isWebsiteAppAlreadyIncluded &&
        planWebsiteEnt &&
        !isWebsiteEntAlreadyInOrder && {
          entitlements: [
            ...selectedOptions,
            {
              ...planWebsiteEnt,
              alreadyIncluded: true,
              ...(sensisSiteDomain && {sensisSiteDomain}),
            },
          ],
        }),
      ...(wasWebsiteAppAddedToCart &&
        planWebsiteEnt && {
          entitlements: [
            ...selectedOptions,
            {
              ...planWebsiteEnt,
              ...(sensisSiteDomain && {sensisSiteDomain}),
            },
          ],
        }),
    }
    const data = {
      centers: [...selectedCentersAndApps.centers],
      apps: [...selectedCentersAndApps.apps],
      oneTimes: [...selectedCentersAndApps.oneTimes],
    }
    const websiteApp = data.apps.find(
      app => app.code === 'addon-5-website_difm',
    )
    if (isSignaturesPaidInCart && selectedPlan.code === 'bc_unlm-5-thryv-m2m') {
      data.apps = data.apps.filter(app => app.code !== 'addon-5-signatures')
    }
    if (includesWebsite && websiteApp) {
      data.apps = data.apps.filter(app => app.code !== 'addon-5-website_difm')
    }
    if (selectedPlan.name.includes(centerType)) {
      data.centers = data.centers.filter(
        plan => !plan.name.includes(selectedPlanWithOptions.name.split(' ')[0]),
      )
    }
    if (selectedPlan.planType === 'center') {
      if (!isCommandCenter) {
        const centerType = selectedPlan.code.split('_')[0]
        const applicableOneTimes = oneTimeFees
          .filter(
            fee =>
              fee.appliesTo.includes(selectedPlanWithOptions.code) &&
              !defaultCentersAndApps.centers.some(({code}) =>
                code.includes(centerType),
              ),
          )
          .filter(
            ({code}) =>
              !selectedCentersAndApps.oneTimes.some(o => o.code === code),
          )
          .filter(({code}) => {
            if (code === 'thryvprfee') {
              return isOnboardingApplicable
            }
            return true
          })
        const uniqueOneTimes = new Set([
          ...data.oneTimes,
          ...applicableOneTimes,
          ...(selectedPlanWithOptions.entitlements?.flatMap(
            ent => ent.applicableOneTimes,
          ) || []),
        ])

        const oneTimes = Array.from(uniqueOneTimes)
          .filter(fee => {
            if (fee?.code === 'thryvdesg2' && isWebsiteFeeNotApplicable) {
              return false
            }
            return true
          })
          .filter(
            (fee, i, arr) =>
              i ===
              arr.findIndex(item => {
                if (item && fee) return item.code === fee.code
                return false
              }),
          )
          .filter(Boolean)

        data.oneTimes = oneTimes
      }
      data.centers.push(selectedPlanWithOptions)
    } else if (selectedPlan.planType === 'app') {
      const applicableOneTimes = oneTimeFees
        .filter(
          fee =>
            fee.appliesTo.includes(selectedPlanWithOptions.code) &&
            !defaultCentersAndApps.centers.some(({code}) =>
              code.includes(centerType),
            ),
        )
        .filter(
          ({code}) =>
            !selectedCentersAndApps.oneTimes.some(o => o.code === code),
        )
        .filter(({code}) => {
          if (code === 'thryvdesg2' && isWebsiteFeeNotApplicable) {
            return false
          }
          return true
        })
      const uniqueOneTimes = new Set([
        ...data.oneTimes,
        ...applicableOneTimes,
        ...(selectedPlanWithOptions.entitlements?.flatMap(
          ent => ent.applicableOneTimes,
        ) || []),
      ])
      const oneTimes = Array.from(uniqueOneTimes).filter(Boolean)

      data.oneTimes = oneTimes
      if (selectedPlanWithOptions.code === 'addon-5-mcexpangr') {
        data.apps = data.apps.filter(plan => plan.code !== 'addon-5-mcstartgr')
      }
      data.apps.push(selectedPlanWithOptions)
    } else {
      data.oneTimes.push(selectedPlanWithOptions)
    }

    let BC_PLANS = [
      'bc_plus-5-thryv-m2m',
      'bc_pro-5-thryv-m2m',
      'bc_unlm-5-thryv-m2m',
    ]

    data.centers.map(item => {
      if (BC_PLANS.includes(item.code)) {
        let uniqEnitlementCode = []
        item.entitlements = item.entitlements.filter(option => {
          if (uniqEnitlementCode.includes(option.code)) {
            return false
          } else {
            uniqEnitlementCode.push(option.code)
            return true
          }
        })
      }
      return item
    })
    setSelectedCentersAndApps(data)
    setSelectedPlan(null)
    setSelectedOptions([])
    if (upgradePaymentPreview) setUpgradePaymentPreview(null)
    if (futureBillingDate) setFutureBillingDate(undefined)
  }
  const handleChangeQuantity = value => {
    setQuantity(value)
  }
  const handleChangeLogoQty = event => {
    const qty = event.target.value
    if (isNaN(qty)) {
      toast.error('Please enter a number')
    } else if (qty > 100 || qty < 1) {
      toast.error('Please enter an amount between 1 and 100')
      setIsContinueDisableLogo(true)
    } else if (qty <= 100 && qty > 0) {
      setIsContinueDisableLogo(false)
    }
    setQuantity(qty)
  }

  const renderContinueButton = () => {
    const isButtonDisabled =
      isContinueDisabledForThryvLeads || quantity === 0 || isContinueDisableLogo

    const toastMessage =
      isMCProEnhanced && mcDomainConfig.isValid && !cohortEaid
        ? 'Invalid EAID- For Legacy Upgrades Only'
        : 'Valid Domain Required'

    const handleClick =
      (isContinueDisabledForMC || isContinueDisabledForSEO) && !isMCProEnhanced
        ? () => toast.error(toastMessage)
        : handleAddToOrder

    const buttonColor =
      (isContinueDisabledForMC || isContinueDisabledForSEO) && !isMCProEnhanced
        ? {color: '#a7a7a7'}
        : null

    return (
      <Button
        data-testid="add-to-order-button"
        variant="primary"
        buttonType="outlined"
        onClick={handleClick}
        disabled={
          isMCProEnhanced && selectedOptions.length < 1
            ? true
            : isButtonDisabled
        }
        style={buttonColor}
      >
        Add to Order
      </Button>
    )
  }

  useEffect(() => {
    const isWebsiteAppInCart = checkIfAdded(
      selectedCentersAndApps?.apps,
      'Professionally Designed Website',
      'app',
    )
    const isWebsiteEntInCart = checkIfAdded(
      selectedCentersAndApps?.centers,
      'Professionally Designed Website',
      'ent',
    )

    if (isWebsiteAppInCart && isWebsiteEntInCart) {
      const sensisSiteDomainInCart = selectedCentersAndApps.apps.find(
        ({code}) => code === 'addon-5-website_difm',
      )?.sensisSiteDomain
      const centerWithWebsiteEnt = selectedCentersAndApps?.centers?.find(
        ({entitlements}) =>
          entitlements?.some(({code}) => code === 'ent-5-website'),
      )
      const newEntitlements = centerWithWebsiteEnt?.entitlements?.map(ent => {
        if (ent.code === 'ent-5-website') {
          return {
            ...ent,
            ...(sensisSiteDomainInCart && {
              sensisSiteDomain: sensisSiteDomainInCart,
            }),
          }
        }
        return ent
      })
      const newCenterWithWebsiteEnt = {
        ...centerWithWebsiteEnt,
        entitlements: newEntitlements,
      }

      const newSelectedCentersAndApps = {
        ...selectedCentersAndApps,
        ...(sensisSiteDomainInCart && {
          centers: selectedCentersAndApps.centers.map(center => {
            if (center.code === centerWithWebsiteEnt.code) {
              return newCenterWithWebsiteEnt
            }
            return center
          }),
        }),
        apps: selectedCentersAndApps?.apps?.filter(
          ({code}) => code !== 'addon-5-website_difm',
        ),
      }
      setSelectedCentersAndApps(newSelectedCentersAndApps)
    }
  }, [selectedCentersAndApps, setSelectedCentersAndApps])

  useEffect(() => {
    if (selectedPlan) {
      if (selectedPlan.name === 'Thryv Leads') {
        setPlanPrice(
          formatPrice(thryvLeadsValidation?.budget || selectedPlan.amount),
        )
      } else if (selectedPlan.name.includes('SEO')) {
        setPlanPrice(
          formatPrice(proposalValidation?.price || selectedPlan.amount),
        )
      } else if (itemConfig?.hasInput || itemConfig?.hasSlider) {
        setPlanPrice(formatPrice(selectedPlan?.amount * quantity))
      } else {
        setPlanPrice(formatPrice(selectedPlan.amount))
      }
    }
  }, [
    itemConfig?.hasInput,
    itemConfig?.hasSlider,
    quantity,
    selectedPlan,
    thryvLeadsValidation?.budget,
    proposalValidation?.price,
  ])

  return (
    <CatalogSectionContainer
      style={{
        padding: '17px 20px 0 20px',
        borderLeft: '1px solid #E3E6E8',
      }}
    >
      <ParagraphText className="mb-3 font-weight-bold" variant="lg">
        Options
      </ParagraphText>
      {selectedPlan && (
        <>
          <div
            className="options-info_container"
            data-testid="options-container"
          >
            <img
              src={selectedPlan?.img}
              alt="plan logo"
              height={64}
              width={64}
              className="options-logo"
            />
            <div>
              <ParagraphText
                variant="lg"
                style={{margin: 0}}
                className="font-weight-bold"
              >
                {selectedPlan?.name}
              </ParagraphText>
              <ParagraphText variant="reg" style={{margin: 0}}>
                {isSEO && !proposalValidation ? 'Varies' : planPrice}
              </ParagraphText>
            </div>
          </div>
          <div style={{paddingLeft: '15px'}}>
            {selectedPlan?.entitlements
              ?.filter(ent => {
                const isSocialContentSelected = selectedOptions.some(
                  option => option.name === 'Social Content Generation',
                )
                const isBCUnlimitedSelected =
                  selectedPlan?.code === 'bc_unlm-5-thryv-m2m'
                const isBCProfessionalIncluded =
                  defaultCentersAndApps?.centers?.find(
                    ({code}) => code === 'bc_pro-5-thryv-m2m',
                  )
                const purchasedBeforeCutoff =
                  isBCProfessionalIncluded &&
                  dayjs(isBCProfessionalIncluded?.createdAt).isBefore(
                    '02-08-2024',
                  )
                const isSCG = ent.code === 'ent-5-socialcontentgen'

                if (
                  isBCProfessionalIncluded &&
                  !isBCUnlimitedSelected &&
                  !purchasedBeforeCutoff &&
                  isSCG
                ) {
                  return false
                }

                if (
                  !isBCUnlimitedSelected &&
                  !isBCProfessionalIncluded &&
                  isSCG
                ) {
                  return false
                }

                if (ent.name === 'Social Boost' && !isSocialContentSelected) {
                  return false
                }
                return true
              })
              .map(option => {
                const isWebsiteAddedAsEnt = checkIfAdded(
                  selectedCentersAndApps.centers,
                  'Professionally Designed Website',
                  'ent',
                )
                const isWebsiteAddedAsApp = checkIfAdded(
                  selectedCentersAndApps.apps,
                  'Professionally Designed Website',
                  'app',
                )
                const isGPOOptionAdded = checkIfAdded(
                  selectedCentersAndApps.centers,
                  'Google Business Profile Optimization',
                  'ent',
                )
                const isOptionIncluded = selectedCentersAndApps.centers.some(
                  ({entitlements}) =>
                    entitlements?.some(
                      ({name, alreadyIncluded}) =>
                        name === option.name && alreadyIncluded,
                    ),
                )

                const isOptionSelected =
                  selectedOptions.find(
                    current => current.name === option.name,
                  ) !== undefined

                const isWebsiteOptionDisabled =
                  option.name === 'Professionally Designed Website' &&
                  isWebsiteAddedAsEnt
                const isGBPOptionDisabled =
                  option.name === 'Google Business Profile Optimization' &&
                  isGPOOptionAdded
                const isWebsiteEntDisabled =
                  option.name === 'Professionally Designed Website' &&
                  isWebsiteAddedAsApp
                const isEntDisabled =
                  isOptionIncluded || isWebsiteEntDisabled || option.isRequired

                return (
                  <React.Fragment key={option.code}>
                    <div className="d-flex align-items-center justify-content-between">
                      {isEntDisabled ? (
                        <Checkbox
                          isChecked={true}
                          disabled={true}
                          value={option}
                          label={
                            <label style={{color: '#808080', margin: 0}}>
                              {option.name === 'Custom Video' && isCanAusNz
                                ? 'QuickStart Video'
                                : option.name}
                            </label>
                          }
                          hasLabel={true}
                        />
                      ) : (
                        <Checkbox
                          data-testid={`select-option-${option.name}`}
                          isChecked={selectedOptions.some(
                            selectedOption =>
                              selectedOption.name === option.name,
                          )}
                          disabled={
                            isWebsiteOptionDisabled || isGBPOptionDisabled
                          }
                          value={option}
                          onChange={() => handleSelectOption(option)}
                          label={
                            <label
                              style={{
                                color: '#808080',
                                margin: 0,
                                textDecorationLine:
                                  isWebsiteOptionDisabled || isGBPOptionDisabled
                                    ? 'line-through'
                                    : 'none',
                              }}
                            >
                              {option.name === 'Custom Video' && isCanAusNz
                                ? 'QuickStart Video'
                                : option.name}
                            </label>
                          }
                          hasLabel={true}
                        />
                      )}
                      <ParagraphText
                        variant="reg"
                        style={{margin: 0, marginBottom: '0.5rem'}}
                      >
                        {option.amount}
                      </ParagraphText>
                    </div>
                    {isOptionSelected &&
                      option?.applicableOneTimes
                        .filter(fee => {
                          if (
                            fee.code === 'thryvdesg2' &&
                            isWebsiteFeeNotApplicable
                          ) {
                            return false
                          }
                          return true
                        })
                        .map(ent => (
                          <div
                            key={ent.code}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <Checkbox
                              isChecked={true}
                              disabled={true}
                              value={ent}
                              label={
                                <label style={{color: '#808080', margin: 0}}>
                                  {ent.name}
                                </label>
                              }
                              hasLabel={true}
                            />
                            <ParagraphText
                              variant="reg"
                              style={{margin: 0, marginBottom: '0.5rem'}}
                            >
                              {formatPrice(ent.amount)}
                            </ParagraphText>
                          </div>
                        ))}
                  </React.Fragment>
                )
              })}
          </div>
          {selectedPlan?.tier && (
            <>
              <div className="d-flex" style={{columnGap: '8px'}}>
                <ParagraphText variant="reg" className="font-weight-bold">
                  Seat amount:
                </ParagraphText>
                <ParagraphText variant="reg">{seatQty}</ParagraphText>
              </div>
              <div className="d-flex" style={{columnGap: '8px'}}>
                <ParagraphText variant="reg" className="font-weight-bold">
                  Price per seat:
                </ParagraphText>
                <ParagraphText variant="reg">
                  {formatPrice(selectedPlan?.tier.unitAmount)}
                </ParagraphText>
              </div>
            </>
          )}
          {isSEO && (
            <SEOValidation
              setProposalId={setProposalId}
              proposalId={proposalId}
              proposalValidation={proposalValidation}
              setProposalValidation={setProposalValidation}
              seoBillingDate={futureBillingDate}
              setSeoBillingDate={setFutureBillingDate}
              isNonMBG={selectedPlan?.name === 'SEO Non-Guaranteed'}
              selectedCountry={selectedCountry}
              isSEOMBGDisabled={isSEOMBGDisabled}
              setIsSEOMBGDisabled={setIsSEOMBGDisabled}
              selectedCentersAndApps={selectedCentersAndApps}
              recommendedSEOPrice={recommendedSEOPrice}
              convertSeoNg={convertSeoNg}
              seoDomainConfig={seoDomainConfig}
              setSeoDomainConfig={setSeoDomainConfig}
            />
          )}
          {isMarketingCenter && !isMCProEnhanced && (
            <>
              <DomainValidation
                setDomainConfig={setMcDomainConfig}
                domainConfig={mcDomainConfig}
              />
              {selectedCountry === 'US' && (
                <EaidValidation
                  cohortEaid={cohortEaid}
                  isEaidFound={isEaidFound}
                  isOptional={selectedPlan.code !== 'mc_pro_enh-5-thryv-m2m'}
                  setCohortEaid={setCohortEaid}
                  setIsEaidFound={setIsEaidFound}
                  setAllowInvoice={setAllowInvoice}
                  setIsCohort={setIsCohort}
                  isMCProEnhanced={isMCProEnhanced}
                  setIsLegacyUpgradeAllowed={setIsLegacyUpgradeAllowed}
                  thryvId={thryvId}
                />
              )}
            </>
          )}
          {isThryvLeads && (
            <ThryvLeadsValidation
              thryvLeadsId={thryvLeadsId}
              setThryvLeadsId={setThryvLeadsId}
              thryvLeadsValidation={thryvLeadsValidation}
              setThryvLeadsValidation={setThryvLeadsValidation}
              thryvLeadsBillingDate={futureBillingDate}
              setThryvLeadsBillingDate={setFutureBillingDate}
              isEaidFound={isEaidFound}
              setIsEaidFound={setIsEaidFound}
              cohortEaid={cohortEaid}
              setCohortEaid={setCohortEaid}
              country={selectedCountry}
              setAllowInvoice={setAllowInvoice}
              thryvId={thryvId}
            />
          )}
          {itemConfig?.hasSlider && (
            <SliderRange
              key={selectedPlan.name}
              product={selectedPlan}
              handleOnChange={handleChangeQuantity}
              maxValue={itemConfig.max}
              range={itemConfig.range}
              symbolTemplate={''}
              includeProduct={true}
              lastValidValue={quantity}
            />
          )}
          {itemConfig?.hasInput && (
            <Input
              className="w-50"
              type="number"
              value={quantity}
              onChange={handleChangeLogoQty}
              max={100}
              min={1}
              size={1}
              placeholder="Please enter a number between 1-100"
              aria-label="Please enter a number between 1-100"
              name="number"
              withLabel
              labelType="floating"
              variant="default"
            />
          )}
          <div
            className="d-flex justify-content-end"
            style={{paddingTop: '25px'}}
          >
            {renderContinueButton()}
          </div>
        </>
      )}
      <WebsiteSettingsModal
        isWebsiteSettingsModalVisible={isWebsiteSettingsModalVisible}
        setIsWebsiteSettingsModalVisible={setIsWebsiteSettingsModalVisible}
        setSensisSiteDomain={setSensisSiteDomain}
      />
    </CatalogSectionContainer>
  )
}

export {Options}
