import React from 'react'
import {ParagraphText} from '@thryvlabs/maverick'
import {formatPrice} from '../../utils/currency-format'

const CENTER_PLANS = ['command center', 'business center', 'marketing center']

const CartItem = ({item, isToday = false, paymentPreview}) => {
  const nameLower = item.name.toLowerCase()
  const isSEOOrTL =
    nameLower.includes('seo') || nameLower.includes('thryv leads')
  const itemQuantity =
    isToday && paymentPreview && isSEOOrTL ? 1 : item.quantity || 1
  const planPrice = item.currency?.unitAmount * itemQuantity
  const isCenter = CENTER_PLANS.some(plan =>
    item.name.toLowerCase().includes(plan),
  )
  return (
    <div className="monthly_container">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex" style={{columnGap: '8px'}}>
          {isCenter && (
            <img
              style={{borderRadius: '5px'}}
              src={item.img}
              alt={item.plan}
              height="24px"
              width="24px"
            />
          )}
          <ParagraphText
            variant="reg"
            className="m-0"
            style={{color: '#4D4D4D'}}
          >
            {item.name}
            {item.quantity && item.quantity > 1 && !isSEOOrTL
              ? ` (${item.quantity}x)`
              : ''}
          </ParagraphText>
        </div>
        <ParagraphText variant="reg" className="m-0" style={{color: '#4D4D4D'}}>
          {item.isCoupon && '-'}
          {formatPrice(planPrice)}
        </ParagraphText>
      </div>
    </div>
  )
}

export {CartItem}
