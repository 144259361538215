import React from 'react'
import {ADDONS_TYPES, BILLING_PREFERENCES} from '../utils/constants'
import {CartProducts} from './cart-products'
import {ParagraphText} from '@thryvlabs/maverick'
import {ADD_ON} from '../utils/addons-data'
import {formatPrice} from '../utils/currency-format'

function CartPaymentDetails({
  listOfAddons,
  totalDiscount,
  autoCouponInfo,
  orderCouponCodes,
  selectedCountry,
  currentStep,
  setCartProducts,
  cartProducts,
  monthlyBilling,
  semiAnnuallyBilling,
  currentPlanName,
  isNewOrder = true,
  upgradeAccountInfo,
}) {
  const discountedOrder = autoCouponInfo?.length > 0
  const getTotalPrice = products => {
    let totalPrice = 0
    for (const i in products) {
      if (typeof products[i].price === 'string') {
        totalPrice +=
          parseFloat(
            products[i].price.replace(',', '').replace(/[^\d.-]/g, ''),
          ).toFixed(2) * 1
      } else {
        totalPrice += products[i].price
      }
    }
    return totalPrice.toFixed(2)
  }
  const getTotalNumberPrice = products => {
    let totalPrice = 0
    for (const i in products) {
      if (typeof products[i].price === 'string') {
        totalPrice +=
          parseFloat(
            products[i].price.replace(',', '').replace(/[^\d.-]/g, ''),
          ).toFixed(2) * 1
      } else {
        totalPrice += products[i].price
      }
    }
    return totalPrice
  }
  const handleInsertProducts = products => {
    const result = []
    products.forEach(product => {
      if (parseFloat(product.price).toFixed(2) > 0) {
        result.push({...product, price: `$${product.price}`})
      } else if (product.product === 'Credit') {
        result.push(product)
      }
    })
    return result
  }
  React.useEffect(() => {
    let listOfSelectedAddons = [...listOfAddons]
    const discount = totalDiscount
    const designFeeDiscount =
      orderCouponCodes?.find(({code}) => code === 'urldbdesignfee')
        ?.discountAmount || 0
    const selectedPlan = listOfSelectedAddons.find(
      ({type}) => type === ADDONS_TYPES['SELECTED_PLAN'],
    )
    if (
      ADD_ON.add_ons['Marketing Center Plus'].addOnCodes.includes(
        selectedPlan.planCode,
      ) ||
      selectedPlan.product === 'Thryv Leads'
    ) {
      const newListOfSelectedAddons = listOfSelectedAddons.filter(
        ({type}) => type !== ADDONS_TYPES['SELECTED_PLAN'],
      )
      const actualSelectedPlan = {
        type: ADDONS_TYPES['SELECTED_PLAN'],
        product: upgradeAccountInfo.planName,
        price: `$${upgradeAccountInfo.planPrice.toFixed(2)}`,
        planCode: upgradeAccountInfo.planCode,
        billingPreference: selectedPlan.billingPreference,
      }
      newListOfSelectedAddons.push(actualSelectedPlan)
      listOfSelectedAddons = newListOfSelectedAddons
    }
    const formattedAddons = [
      {
        product: 'Addons',
        type: ADDONS_TYPES['ADD_ON'],
        price: getTotalPrice(
          listOfSelectedAddons.filter(
            ({type, billingPreference, planCode}) =>
              type === ADDONS_TYPES['ADD_ON'] &&
              billingPreference !== BILLING_PREFERENCES[2] &&
              planCode !== '247support-m2m',
          ),
        ),
      },
      {
        product: 'One-time',
        type: ADDONS_TYPES['ADD_ON'],
        price: getTotalPrice(
          listOfSelectedAddons.filter(
            ({type, billingPreference}) =>
              type === ADDONS_TYPES['ONE_TIME'] ||
              billingPreference === BILLING_PREFERENCES[2],
          ),
        ),
      },
      {
        product: isNewOrder ? 'Subtotal' : 'Due Today',
        price: parseFloat(
          getTotalNumberPrice(
            listOfSelectedAddons.filter(
              ({type, billingPreference}) =>
                type === ADDONS_TYPES['ONE_TIME'] ||
                billingPreference === BILLING_PREFERENCES[2],
            ),
          ) +
            getTotalNumberPrice(
              listOfSelectedAddons.filter(
                ({type, billingPreference}) =>
                  type === ADDONS_TYPES['ADD_ON'] &&
                  billingPreference !== BILLING_PREFERENCES[2],
              ),
            ) +
            getTotalNumberPrice(
              listOfSelectedAddons.filter(
                ({type, billingPreference}) =>
                  type === ADDONS_TYPES['SELECTED_PLAN'] && billingPreference,
              ),
            ) +
            getTotalNumberPrice(
              listOfSelectedAddons.filter(
                ({type, product}) =>
                  type === ADDONS_TYPES['SELECTED_PLAN'] &&
                  product === 'Onboarding Fee',
              ),
            ) -
            discount +
            designFeeDiscount +
            (upgradeAccountInfo?.balanceInfo?.amount || 0),
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        highlighted: true,
      },
      {
        product: 'Due Semi-Annually',
        type: ADDONS_TYPES['SELECTED_PLAN'],
        price: getTotalPrice(
          listOfSelectedAddons.filter(
            ({billingPreference}) =>
              billingPreference === BILLING_PREFERENCES[1],
          ),
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        highlighted: true,
      },
      {
        product: 'Credit',
        price: formatPrice(upgradeAccountInfo?.balanceInfo?.amount || 0),
        highlighted: true,
      },
      {
        product: 'Due Monthly',
        type: ADDONS_TYPES['SELECTED_PLAN'],
        price: getTotalPrice(
          listOfSelectedAddons.filter(
            ({billingPreference}) =>
              billingPreference === BILLING_PREFERENCES[0],
          ),
        )
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        // price: (
        //   Number(
        //     getTotalPrice(
        //       listOfSelectedAddons.filter(
        //         ({billingPreference}) =>
        //           billingPreference === BILLING_PREFERENCES[0],
        //       ),
        //     ),
        //   ) + upgradeAccountInfo?.balanceInfo?.amount || 0
        // )
        //   .toFixed(2)
        //   .toString()
        //   .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
        highlighted: true,
      },
    ]
    const supportFee = listOfAddons.find(
      ({planCode}) => planCode === '247support-m2m',
    )
    if (supportFee) {
      const supportFeeItem = {
        product: 'Support Fee',
        price: supportFee.price.replace('$', ''),
        highlighted: false,
      }
      const dueTodayIndex = formattedAddons.findIndex(
        ({product}) =>
          product === 'Due Today' ||
          product === 'Subtotal' ||
          product === 'Credit',
      )
      formattedAddons.splice(dueTodayIndex, 0, supportFeeItem)
    }
    if (discount > 0) {
      formattedAddons.splice(2, 0, {
        product: 'Incentive',
        price: parseFloat(totalDiscount).toFixed(2),
        elements: orderCouponCodes,
        highlighted: false,
      })
    }
    setCartProducts([
      ...listOfSelectedAddons.filter(
        ({type}) => type === ADDONS_TYPES['SELECTED_PLAN'],
      ),
      ...handleInsertProducts(formattedAddons),
    ])
  }, [
    listOfAddons,
    totalDiscount,
    autoCouponInfo,
    orderCouponCodes,
    setCartProducts,
    isNewOrder,
    upgradeAccountInfo?.planCode,
    upgradeAccountInfo?.planName,
    upgradeAccountInfo?.planPrice,
  ])

  return (
    <>
      <CartProducts
        listOfProducts={cartProducts || []}
        selectedCountry={selectedCountry}
        currentStep={currentStep}
        monthlyBilling={monthlyBilling}
        semiAnnuallyBilling={semiAnnuallyBilling}
        currentPlanName={currentPlanName}
        discountedOrder={discountedOrder}
        currentPlanPrice={upgradeAccountInfo?.planPrice}
      />
      {(selectedCountry === 'AU' || selectedCountry === 'aus') &&
        currentStep !== 7 && (
          <div>
            <ParagraphText
              variant="sm"
              className="font-weight-bold mt-4
        "
            >
              + Applicable Fees
            </ParagraphText>
            <ParagraphText
              variant="sm"
              className="font-weight-bold mt-4
          "
            >
              *All plans include GST except SEO.
            </ParagraphText>
          </div>
        )}
    </>
  )
}

export {CartPaymentDetails}
