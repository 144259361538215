function getUpgradeIncentivePlans(
  selectedCenters,
  defaultCenters,
  paymentPreview,
) {
  const defaultCenterTypes = defaultCenters.map(({name}) => name.split(' ')[0])
  const excludedCenters = ['mc_pro_enh-5-thryv-m2m']
  let matchedPlanNames = []

  for (const selectedCenter of selectedCenters) {
    const centerType = selectedCenter.name.split(' ')[0]
    const isCenter = selectedCenter.name.includes('Center')

    if (excludedCenters.includes(selectedCenter.code)) {
      continue
    }

    if (!paymentPreview) {
      if (centerType === 'Command') {
        const selectedCC = selectedCenter
        const defaultCC = defaultCenters.find(center =>
          center.name.includes('Command Center'),
        )

        if (selectedCC.name !== defaultCC.name) {
          matchedPlanNames.push(selectedCenter.name.toLowerCase())
        }
      }

      if (!defaultCenterTypes.includes(centerType) && isCenter) {
        matchedPlanNames.push(selectedCenter.name.toLowerCase())
      }
    }

    if (paymentPreview && isCenter) {
      const hasDiscount = paymentPreview.centers.some(
        ({planCode, discount}) => selectedCenter.code === planCode && discount,
      )
      if (hasDiscount && paymentPreview?.isTransitionIncentiveCoupon) {
        matchedPlanNames.push(selectedCenter.name.toLowerCase())
      }
    }
  }
  return matchedPlanNames
}

export {getUpgradeIncentivePlans}
