import React from 'react'

const ADD_ON = {
  entitlements: {
    Website: {
      label: 'Website',
      setupFeeLabel: 'One Time Design Fee',
      description: [
        <p key={0}>
          Professionally designed to fit your brand and business, you&apos;ll
          get a mobile responsive website viewable from any device. Easily
          engage visitors while capturing potential customers through
          Thryv&apos;s fully integrated client login area, forms, payments,
          online appointment scheduling and links to your social media profiles.
          Ensure you get found and a positive viewing experience with a
          guaranteed 90+ Google Website Speed Score. Website personalization
          allows you to deliver specific messages based on visitor day, time,
          location, and visit frequency. A team of specialized copywriters and
          designers will consult with you to understand your website goals, tone
          and style will be hard at work for you.
        </p>,
      ],
    },
    'Custom Video': {
      label: 'Custom Video',
      description: [
        <p key={0}>
          Through a full motion, on-site video shoot with our professional
          videographers, your story is captured showcasing your competitive
          advantage. Thryv Custom Video is a way to introduce and personalize
          your business by highlighting your products and services, sharing
          testimonials, sharing before and after, and more.
        </p>,
      ],
    },
    'Auto Video': {
      label: 'Auto Video',
      description: [
        <p key={0}>
          Automatically generated and always fresh by scraping the web every 3
          months as needed, Automatic Video tells your businesses story in 30
          seconds through music, text and photos.
        </p>,
      ],
    },
    'Review Acknowledgment': {
      label: 'Review Acknowledgment',
      description: [
        <p key={0}>
          Over 53% of customers surveyed state that they expect businesses to
          respond to their online reviews, yet 63% reporting they have never
          been responded to. As a small business owner, we know how busy you
          are, so allow the Thryv team to acknowledge all of your Thryv, Google,
          and Facebook reviews within 24 hours of receipt. Ensure everyone who
          reads your reviews knows your business is attentive and responsive to
          your customers feedback, and take control of your online reputation.
        </p>,
      ],
    },
    'Social Content Generation': {
      label: 'Social Content Generation',
      description: [
        <p key={0}>
          Your business has an amazing story to tell, let the Thryv Social
          Content Team write unique content for your business every month.
          Thryv’s Facebook boosting algorithms are steroids for your posts. Grow
          your audience and attract more fans by reaching highly targeted
          potential customers by jumping to the top of their news feeds.
        </p>,
      ],
    },
    'Social Boost': {
      label: 'Social Boost',
      description: [
        <p key={0}>
          Your business has an amazing story to tell, let the Thryv Social
          Content Team write unique content for your business every month.
          Thryv&apos;s Facebook boosting algorithms are steroids for your posts.
          Grow your audience and attract more fans by reaching highly targeted
          potential customers by jumping to the top of their news feeds.
        </p>,
      ],
    },
    'Google My Business Optimization': {
      label: 'Google My Business Optimization',
      description: [
        <p key={0}>
          Thryv optimizes and manages your Google My Business listing daily to
          help increase your ranking, get found organically and get selected
          above your competition. An optimized listing means quality engagement
          to your profile, that converts searches into customers. Includes
          performance metrics and analytics for full transparency. Since a
          Website is required for GMB Optimization, a Thryv Website pairs
          perfectly.
        </p>,
      ],
    },
    'Standard Domain Based Gmail': {
      label: 'Standard Domain Based Gmail',
      description: [
        <p key={0}>
          Gmail Email includes professional domain-based email for your business
          giving you a consistent online brand with a vanity URL. Offering more
          features and options beyond standard Gmail, including better email
          interface, larger cloud-based storage capacity and free of ads. Paired
          with Thryv&apos;s Gmail Integration, bringing Thryv&apos;s CRM into
          your email for a perfect sync of your data.
        </p>,
      ],
    },
  },
  add_ons: {
    'Thryv Leads': {
      label: 'Thryv Leads',
      description: [
        <p key={0}>
          Thryv Leads provides an integrated leads solution that simplifies the
          very complex space of online advertising with a simple equation of X
          investment provides Y number of leads. We do this by reaching your
          clients where they are engaging online whether it is through search
          engines, social media or simply browsing. Pricing by proposal only.
        </p>,
      ],
      addOnCodes: ['addon-4-tl-m2m'],
    },
    'Thryv CSN': {
      label: 'Thryv CSN',
      description: [
        <p key={0}>
          Our Consumer Search Network helps expose your business to more
          ready-to-buy consumers by providing you with a robust presence on our
          proprietary sites – dexknows.com®, superpages.com® and
          yellowpages.com® and our CSN partner sites.
        </p>,
      ],
    },
    'Additional Social Boosting': {
      label: 'Additional Social Boosting',
      description: [
        <p key={0}>
          Your business has an amazing story to tell, let the Thryv Social
          Content Team write unique content for your business every month.
          Thryv&apos;s Facebook boosting algorithms are steroids for your posts.
          Grow your audience and attract more fans by reaching highly targeted
          potential customers by jumping to the top of their news feeds.
        </p>,
      ],
    },
    'Additional Video Views': {
      label: 'Additional Video Views',
      description: [
        <p key={0}>
          15-second Video Advertisements are created from your custom videos and
          are distributed to top networks to gain online exposure and views.
          They target over 2 billion people daily based off your unique
          geographical location, putting your business on the map with
          guaranteed video views.
        </p>,
      ],
    },
    'Marketing Center Plus': {
      label: 'Marketing Center Plus',
      addOnCodes: ['addon-4-mc-semi', 'addon-4-mc-m2m'],
      description: [
        <p key={0}>
          Marketing Center is a tool within Thryv to create and run
          advertisements online across various channels. It will scrape your
          current website for ad copy and images, and use this information to
          create a streamlined, omni-channel ad campaign with consistent
          branding. It will allow you to allocate your ad budget across these
          various channels, and provide recommendations along the way using
          machine learning to improve performance. Marketing Center will also
          track the performance of your website, and help you manage your Google
          My Business Listing.
        </p>,
      ],
    },
    Website: {
      label: 'Website',
      description: [
        <p key={0}>
          Professionally designed to fit your brand and business, you&apos;ll
          get a mobile responsive website viewable from any device. Easily
          engage visitors while capturing potential customers through
          Thryv&apos;s fully integrated client login area, forms, payments,
          online appointment scheduling and links to your social media profiles.
          Ensure you get found and a positive viewing experience with a
          guaranteed 90+ Google Website Speed Score. Website personalization
          allows you to deliver specific messages based on visitor day, time,
          location, and visit frequency. A team of specialized copywriters and
          designers will consult with you to understand your website goals, tone
          and style will be hard at work for you.
        </p>,
      ],
    },
    'SEO Non-Guaranteed': {
      label: 'SEO Non-Guaranteed',
      description: [
        <p key={0}>
          Help search engines focus on your business. SEO combined with Thryv®
          will help make your website as visible and prominent as possible on
          search engines like Google™ Search, Yahoo!®and Bing™.
        </p>,
        <ul key={1}>
          <li>
            • Increases your opportunity to get you closer to the top of search
            results
          </li>
          <li> • Helps promote your brand&apos;s popularity </li>
          <li>
            50% of Active Keywords will reach page one of Google within the
            first 6 months of the program term, or we guarantee your money back
          </li>
          <li>
            • Websites with video get 50 times better page rankings on Google
          </li>
        </ul>,
        <p key={2} style={{fontWeight: 'bold'}}>
          Google and Adwords are trademarks of Google Inc. and are registered in
          the US and other countries. Yahoo! and Bing and their logos are
          registered trademarks of Yahoo! Inc. and Microsoft, respectively.
        </p>,
      ],
    },
    'SEO Money Back Guarantee': {
      label: 'SEO Money Back Guarantee',
      description: [
        <p key={0}>
          Help search engines focus on your business. SEO combined with Thryv®
          will help make your website as visible and prominent as possible on
          search engines like Google™ Search, Yahoo!®and Bing™.
        </p>,
        <ul key={1}>
          <li>
            • Increases your opportunity to get you closer to the top of search
            results
          </li>
          <li> • Helps promote your brand&apos;s popularity </li>
          <li>
            • 50% of Active Keywords will reach page one of Google within the
            first 6 months of the program term, or we guarantee your money back
          </li>
          <li>
            • Websites with video get 50 times better page rankings on Google
          </li>
        </ul>,
        <p key={2} style={{fontWeight: 'bold'}}>
          Google and Adwords are trademarks of Google Inc. and are registered in
          the US and other countries. Yahoo! and Bing and their logos are
          registered trademarks of Yahoo! Inc. and Microsoft, respectively.
        </p>,
        <p key={3} style={{fontWeight: 'bold'}}>
          All conditions of the Money Back Guarantee program must be satisfied.
          You can view all conditions by reviewing the SEO Services Terms and
          Conditions located at thryv.com/terms. QuickSprout.com 2016
        </p>,
      ],
    },
    'Enhanced Accessibility': {
      label: 'Enhanced Accessibility',
      description: [
        <p key={0}>
          Thryv Enhanced Accessibility add-on, AudioEye&apos;s Managed, will
          enable an accessibility toolkit on your Thryv website to improve and
          continually track WCAG and ADA compliance. AudioEye Managed leverages
          technology and manual website modifications to deliver testing,
          remediation, validation and monitoring of website accessibility
          measures for compliance with ADA-related requirements. AudioEye
          provides warranty coverage of up to $1000 for eligible claims.
        </p>,
        <a
          key={1}
          href="https://www.audioeye.com/terms-of-service/"
          target="_blank"
          rel="noreferrer"
        >
          Click Here to Read Full Terms and Conditions.
        </a>,
      ],
    },
    HIPAA: {
      label: 'HIPAA',
      description: [
        <p key={0}>
          Thryv HIPAA add-on offers extra privacy and security protections to
          help safeguard your data, so you can use Thryv and comply with your
          HIPAA obligations. Our platform, backend processes, and support comply
          with applicable HIPAA privacy and security rule standards. We provide
          a BAA detailing our obligations regarding your PHI within the Thryv
          SaaS.
        </p>,
      ],
    },
    'Restricted Access': {
      label: 'Restricted Access',
      description: [
        <p key={0}>
          The Thryv Restricted add-on available to Non-HIPAA Covered Entities,
          offers extra privacy and security protections to help safeguard your
          data, so you can use Thryv and comply with your industry security
          standards. Our platform, backend processes, and support comply with
          applicable privacy and security rule standards.
        </p>,
      ],
    },
    'Google My Business Optimization': {
      label: 'Google My Business Optimization',
      description: [
        <p key={0}>
          Thryv optimizes and manages your Google My Business listing daily to
          help increase your ranking, get found organically and get selected
          above your competition. An optimized listing means quality engagement
          to your profile, that converts searches into customers. Includes
          performance metrics and analytics for full transparency. Since a
          Website is required for GMB Optimization, a Thryv Website pairs
          perfectly.
        </p>,
      ],
    },
    'Certified Domain Based Gmails': {
      label: 'Certified Domain Based Gmails',
      description: [
        <p key={0}>
          Gmail Email includes professional domain-based email for your business
          giving you a consistent online brand with a vanity URL. Offering more
          features and options beyond standard Gmail, including better email
          interface, larger cloud-based storage capacity and free of ads. Paired
          with Thryv’s Gmail Integration, bringing Thryv’s CRM into your email
          for a perfect sync of your data. Certified includes more cloud
          storage, security and control.
        </p>,
      ],
    },
    'Standard Domain Based Gmail': {
      label: 'Standard Domain Based Gmail',
      description: [
        <p key={0}>
          Gmail Email includes professional domain-based email for your business
          giving you a consistent online brand with a vanity URL. Offering more
          features and options beyond standard Gmail, including better email
          interface, larger cloud-based storage capacity and free of ads. Paired
          with Thryv&apos;s Gmail Integration, bringing Thryv&apos;s CRM into
          your email for a perfect sync of your data.
        </p>,
      ],
    },
    'Marketing Center Pro': {
      label: 'Marketing Center Pro',
      addOnCodes: ['addon-4-mcpro-m2m'],
      description: [
        <p key={0}>
          Marketing Center is a tool within Thryv to create and run
          advertisements online across various channels. It will scrape your
          current website for ad copy and images, and use this information to
          create a streamlined, omni-channel ad campaign with consistent
          branding. It will allow you to allocate your ad budget across these
          various channels, and provide recommendations along the way using
          machine learning to improve performance. Marketing Center will also
          track the performance of your website, and help you manage your Google
          My Business Listing.
        </p>,
      ],
    },
    'Marketing Center Pro Enhanced': {
      label: 'Marketing Center Pro Enhanced',
      addOnCodes: ['addon-4-mcproenh-m2m'],
      description: [
        <p key={0}>
          Marketing Center is a tool within Thryv to create and run
          advertisements online across various channels. It will scrape your
          current website for ad copy and images, and use this information to
          create a streamlined, omni-channel ad campaign with consistent
          branding. It will allow you to allocate your ad budget across these
          various channels, and provide recommendations along the way using
          machine learning to improve performance. Marketing Center will also
          track the performance of your website, and help you manage your Google
          My Business Listing.
        </p>,
      ],
    },
    'Starter Growth Package': {
      label: 'Starter Growth Package',
      addOnCodes: ['addon-4-mcstartgr-m2m'],
      description: [
        <p key={0}>
          Marketing Center is a tool within Thryv to create and run
          advertisements online across various channels. It will scrape your
          current website for ad copy and images, and use this information to
          create a streamlined, omni-channel ad campaign with consistent
          branding. It will allow you to allocate your ad budget across these
          various channels, and provide recommendations along the way using
          machine learning to improve performance. Marketing Center will also
          track the performance of your website, and help you manage your Google
          My Business Listing.
        </p>,
      ],
    },
    'Expanded Growth Package': {
      label: 'Expanded Growth Package',
      addOnCodes: ['addon-4-mcexpangr-m2m'],
      description: [
        <p key={0}>
          Marketing Center is a tool within Thryv to create and run
          advertisements online across various channels. It will scrape your
          current website for ad copy and images, and use this information to
          create a streamlined, omni-channel ad campaign with consistent
          branding. It will allow you to allocate your ad budget across these
          various channels, and provide recommendations along the way using
          machine learning to improve performance. Marketing Center will also
          track the performance of your website, and help you manage your Google
          My Business Listing.
        </p>,
      ],
    },
  },
  one_time: {
    Logo: {
      label: 'Logo',
      description: [
        <p key={0}>
          Brand your business with a professional logo to uniquely represent
          your company, products or services.
        </p>,
        <ul key={1}>
          <li>• Includes 1 professionally designed logo</li>
          <li>• Branded and designed to meet the needs of the business</li>
          <li>• Includes design consultation</li>
          <li>• 3 logo options to choose from</li>
          <li>
            • You own it! Comes with high resolution download for digital or
            printing needs
          </li>
        </ul>,
      ],
      maxValue: 100,
      range: 1,
    },
    '5 Extra Website Pages': {
      label: '5 Extra Website Pages',
      description: [
        <ul key={0}>
          <li>
            • 5 pages of additional content, including copywriting and images
          </li>
          <li>
            • 200 words a page of professional written copy and images featuring
            your business and/or products
          </li>
          <li>
            • Our team will ensure all images are optimized and tagged with SEO
            elements to make finding your website easier
          </li>
          <li>
            • If you choose to start with just two additional pages, the other
            three pages can be added at any time in the future
          </li>
        </ul>,
      ],
      maxValue: 250,
      range: 5,
      symbol: 'pgs',
    },
    'Photo Package': {
      label: 'Photo Package',
      description: [
        <p key={0}>
          Let your clients discover your business, employees, products and
          services with professional custom photos. Customized photos allow a
          client to see inside your world.
        </p>,
        <ul key={1}>
          <li>
            • Shares your story with 30 high resolution professional photos
          </li>
          <li>
            • Promotes your business by placing images on social, on-line
            listings profiles, website and more
          </li>
          <li>
            • Includes professional photo shoot at your place of business or
            location choice
          </li>
        </ul>,
      ],
    },
    'Custom Video': {
      label: 'Custom Video',
      description: [
        <p key={0}>
          Through a full motion, on-site video shoot with our professional
          videographers, your story is captured showcasing your competitive
          advantage. Thryv Custom Video is a way to introduce and personalize
          your business by highlighting your products and services, sharing
          testimonials, sharing before and after, and more.
        </p>,
      ],
    },
  },
}

export {ADD_ON}
