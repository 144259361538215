import React, {useState, useEffect} from 'react'
import {useAsync} from '../utils/use-async'
import {useLocation} from 'react-router-dom'
import {CountrySelection} from './country-selection'
import {useAuthClient} from '../utils/use-auth-client'
import {IndustryCategorySelection} from './industry-category-selection'
import {AccountInformation} from './account-information'
import {IndustrySelection} from './industry-selection'
import {toast} from 'react-toastify'
import {OnboardingProgram} from './onboarding-program'
import PaymentDetails from './payment-details'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {useTrackLR} from '../utils/use-track-lr'
import {Catalog} from './catalog'
import useAutoCoupons from '../utils/use-auto-coupons'

import {CC_CURRENCIES, CC_PLANS} from '../utils/constants'
import {Commitment} from './commitment'

import {
  MainContainer,
  WizardContainer,
  StepsContainer,
} from '../components/ui/new-order'

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return <div className="d-flex">{children}</div>
  }
  return children
}

function NewOrder() {
  const location = useLocation()
  const {frontendSideNav} = useFlags()
  const {state} = location
  const [order, setOrder] = useState(state?.order || {})
  const [currentStep, setCurrentStep] = useState(state?.currentStep || 1)
  const [accountSubscriptionData, setAccountSubscriptionData] = useState({})
  const [kickoffApptId, setKickoffApptId] = useState(null)
  const [creativeApptId, setCreativeApptId] = useState(null)
  const [onboardingCallDate, setOnboardingCallDate] = useState(null)
  const [creativeCallDate, setCreativeCallDate] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [allowInvoice, setAllowInvoice] = useState(false)
  const [showSmsOption, setShwoSmsOption] = useState(false)
  const [accountInfo, setAccountInfo] = useState({})
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('Credit Card')
  const [allSelectedPurchaseIntent, setAllSelectedPurchaseIntent] = useState({
    firstPurchaseIntent: {},
    secondPurchaseIntent: {},
    thirdPurchaseIntent: {},
    csmExtraInfo: '',
  })
  const [orderCouponCodes, setOrderCouponCodes] = useState([])
  const [clientInitials, setClientInitials] = useState('')
  const [cartProducts, setCartProducts] = useState([])
  const [ccCouponInfo, setCcCouponInfo] = useState()
  const [discountType, setDiscountType] = React.useState(
    'New Client Transition',
  )
  const [incentiveCoupon, setIncentiveCoupon] = React.useState(false)
  const [all5PlanPrices, setAll5PlanPrices] = useState(null)
  const [isEaidFound, setIsEaidFound] = useState(false)
  const [cohortEaid, setCohortEaid] = useState(false)
  const [isCohort, setIsCohort] = useState(false)
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })
  const customEvent = order?.accountInformation && {
    key: 'new-order',
    companyEmail: order.accountInformation.email,
    companyName: order.accountInformation.company,
  }
  useTrackLR(customEvent)
  const {autoCouponInfo, resetAutoCoupons} = useAutoCoupons({
    discountType,
    selectedPackage: order.accountInformation?.selectedPackage,
    selectedIndustryId: order.industryCategoryId,
    selectedCountry: order.selectedCountry,
    selectedCentersAndApps: order.selectedCentersAndApps,
    selectedAddons: order.selectedAddons,
    incentiveCoupon,
    setIncentiveCoupon,
    currentStep,
    setOrderCouponCodes,
    ccCouponInfo,
    isEaidFound,
    isCohort,
  })

  const TOTAL_STEPS = 9
  const isSEOIncluded = order?.selectedCentersAndApps?.centers?.some(({name}) =>
    name.includes('SEO'),
  )

  useEffect(() => {
    const get5PlanPrices = async () => {
      const url = 'plans/5/list'
      const response = await client(url)
      setAll5PlanPrices(response)
    }

    get5PlanPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (kickoffApptId) {
      return () => {
        client(`plans/onboarding`, {
          data: {
            type: 'onboarding',
            country: order?.selectedCountry?.isoValue,
            demoApptId: kickoffApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kickoffApptId])

  useEffect(() => {
    if (creativeApptId) {
      return () => {
        client(`plans/creative`, {
          data: {
            type: 'creative',
            country: order?.selectedCountry?.isoValue,
            demoApptId: creativeApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeApptId])

  useEffect(() => {
    if (ccCouponInfo && orderCouponCodes.length === 0) {
      setOrderCouponCodes([ccCouponInfo])
    }
  }, [ccCouponInfo, orderCouponCodes])

  const nextStep = data => {
    setOrder({...order, ...data})
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }
  const prevStep = () => {
    let _currentStep = currentStep
    if (currentStep <= 1) {
      _currentStep = 1
    } else {
      _currentStep = currentStep - 1
    }
    setCurrentStep(_currentStep)
  }

  const updateAddressInfo = (data, setShowModal) => {
    const insertData = {
      ...order,
      accountInformation: {
        ...order?.accountInformation,
        address: data?.street1,
        postCode: data?.postalCode,
        state: data?.region,
        companyPhone: data?.phone,
        ...data,
      },
    }

    delete insertData.accountInformation.postalCode
    delete insertData.accountInformation.street1
    delete insertData.accountInformation.region
    delete insertData.accountInformation.phone

    setOrder(insertData)
    setShowModal(false)
  }

  const updateInitials = initials => {
    setClientInitials(initials)
  }

  const findWebsiteEntitlement = centers =>
    centers
      .find(({entitlements}) =>
        entitlements.some(
          ({name}) => name === 'Professionally Designed Website',
        ),
      )
      ?.entitlements.find(
        ({name}) => name === 'Professionally Designed Website',
      )

  const findWebsiteApp = ({name}) => name === 'Professionally Designed Website'

  const formatCenters = (centers, callbackFn) =>
    centers
      .filter(callbackFn)
      .map(({code, quantity = 1, entitlements, startDate}) => ({
        code,
        quantity,
        ...(startDate && {startDate}),
        addons: entitlements.map(({code, quantity = 1}) => ({code, quantity})),
      }))
  const sendCCPaymentLink = async (contactMethod, contactInfo) => {
    const demoLeadId = order.accountInformation.leadID || order.leadId
    const {centers, apps, oneTimes} = order.selectedCentersAndApps
    const thryvLeadsId = centers.find(
      ({name}) => name === 'Thryv Leads',
    )?.thryvLeadsId
    const seoProposal = centers.find(({name}) => name.includes('SEO'))
    const sensisDomain = (
      findWebsiteEntitlement(centers) || apps.find(findWebsiteApp)
    )?.sensisSiteDomain
    const mcDomain = centers.find(({name}) =>
      name.toLowerCase().includes('marketing'),
    )?.mcDomain
    const parentSubscriptions = formatCenters(centers, ({name}) =>
      name.includes('Command Center'),
    )
    const marketplace = apps.find(({name}) => name === 'Thryv Marketplace')
    let childSubscriptions = []
    for (let center of centers) {
      if (center?.name.includes('Command Center')) {
        continue
      } else {
        const formattedCenter = formatCenters(centers, ({name}) =>
          name.includes(center?.name),
        )
        childSubscriptions.push(formattedCenter[0])
      }
    }

    childSubscriptions.push({
      code: marketplace.code,
      quantity: 1,
      addons: apps
        .filter(({name, code}) => {
          return name !== 'Thryv Marketplace'
        })
        .map(({code, quantity = 1}) => {
          return {
            code,
            quantity,
          }
        }),
    })

    const oneTimeFees = oneTimes.map(({code, quantity = 1}) => {
      return {
        code,
        quantity,
      }
    })

    const data = {
      firstName: order.accountInformation.firstName,
      lastName: order.accountInformation.lastName,
      email: order.accountInformation.email,
      company: order.accountInformation.company,
      currency: CC_CURRENCIES[order.selectedCountry.value],
      industryId: order.template,
      businessAddress: {
        street: order.accountInformation.address,
        city: order.accountInformation.city,
        state: order.accountInformation.state,
        postalCode: order.accountInformation.postCode,
        country: order.selectedCountry.isoValue,
        phone: order.accountInformation.companyPhone,
      },
      contactPhone: contactInfo,
      thryvId: order.CCAccountInfo.thryvId,
      flow: 'new',
      sendLinkVia: contactMethod,
      parentSubscriptions,
      childSubscriptions,
      oneTimeFees,
      isCohort,
    }
    if (cohortEaid) {
      data.cohortEaid = cohortEaid
    }
    if (
      order.selectedCountry.isoValue === 'KY' ||
      order.selectedCountry.isoValue === 'BB'
    ) {
      data.currency = 'USD'
    }
    if (kickoffApptId) {
      data.onboardingApptId = kickoffApptId
    }
    if (creativeApptId) {
      data.creativeApptId = creativeApptId
    }
    if (order.accountInformation.xpCode?.length > 0) {
      data.xpCode = order.accountInformation.xpCode
    }
    if (
      order.accountInformation.differentSalesRep?.value === true ||
      order.accountInformation.differentSalesRep?.value === 'true'
    ) {
      if (order.accountInformation.behalf) {
        data.behalfRepId = order.accountInformation.behalf
      }
      if (order.accountInformation.behalfFirst) {
        data.behalfFirstName = order.accountInformation.behalfFirst
      }
      if (order.accountInformation.behalfLast) {
        data.behalfLastName = order.accountInformation.behalfLast
      }
      if (order.accountInformation.behalfEmail) {
        data.behalfEmail = order.accountInformation.behalfEmail
      }
    }
    if (order.accountInformation.overrideRep) {
      if (order.accountInformation.overrideRepCode) {
        data.overrideRepId = order.accountInformation.overrideRepCode
      }
      if (order.accountInformation.overrideRepFirst) {
        data.overrideRepFirst = order.accountInformation.overrideRepFirst
      }
      if (order.accountInformation.overrideRepLast) {
        data.overrideRepLast = order.accountInformation.overrideRepLast
      }
      if (order.accountInformation.overrideRepEmail) {
        data.overrideRepEmail = order.accountInformation.overrideRepEmail
      }
    }
    if (
      ['AU', 'NZ'].includes(order.selectedCountry.isoValue) &&
      order.accountInformation.abn
    ) {
      data.abn = order.accountInformation.abn.replaceAll(' ', '')
    }
    if (data?.abn === '') {
      delete data.abn
    }
    if (order.onBoardingProgramData?.csmExtraInfo) {
      data.orderNotes = order.onBoardingProgramData.csmExtraInfo
    }
    if (thryvLeadsId) {
      data.thryvLeadsId = thryvLeadsId
    }
    if (seoProposal) {
      data.seoProposalId = seoProposal.seoProposalId
      data.seoDomain = seoProposal.seoDomain
    }
    if (sensisDomain) {
      data.sensisDomain = sensisDomain
    }
    if (selectedPaymentMethod) {
      data.isInvoiceBilled = selectedPaymentMethod === 'Invoice'
    }
    if (mcDomain) {
      data.mcDomain = mcDomain
    }
    if (demoLeadId) {
      data.demoLeadId = demoLeadId
    }
    if (orderCouponCodes.find(x => x.code === 'transition75')) {
      const couponCodes = ['25month1', '25month2', '25month3']
      const {code} = order.selectedCentersAndApps.centers.find(({name}) =>
        CC_PLANS.some(plan => name.toLowerCase().includes(plan)),
      )
      const createUrl = couponCode =>
        `orders/coupon/validate?couponCode=${couponCode}&country=${order.selectedCountry.isoValue}&planCode=${code}`
      const [transitionCoupon1, transitionCoupon2, transitionCoupon3] =
        await Promise.all(
          couponCodes.map(async code => {
            const url = createUrl(code)
            return await client(url)
          }),
        )
      const transitionCouponIndex = orderCouponCodes.findIndex(
        addonInfo => addonInfo.code === 'transition75',
      )
      const couponsCopy = [...orderCouponCodes]

      couponsCopy.splice(transitionCouponIndex, 1)
      couponsCopy.push(transitionCoupon1, transitionCoupon2, transitionCoupon3)

      const COUPON_CODES = couponsCopy
        .filter(coupon => {
          return coupon.isValid
        })
        .map(coupon => {
          return coupon.code
        })
      if (COUPON_CODES.length > 0) {
        data.coupons = COUPON_CODES
      }
    } else {
      const ALL_COUPON_CODES = orderCouponCodes
        .filter(coupon => {
          return coupon.isValid
        })
        .map(coupon => {
          return coupon.code
        })
      if (ALL_COUPON_CODES.length > 0) {
        data.coupons = ALL_COUPON_CODES
      } else {
        data.coupons = []
      }
    }

    try {
      const result = await run(client('cc/order/send-link', {data}))
      if (result.message === 'Payment link sent') {
        toast.success(result.message)
      } else {
        toast.error(result.message)
      }
      if (result.paymentUuid) {
        return result
      }
    } catch (error) {
      setProcessing(false)
      if (error.status !== 400) {
        toast.error(`Error sending link: ${error.message}`)
        throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(`Error sending link: ${error.message}`)
      }
    }
  }

  const resendPaymentLink = async current => {
    const data = {
      orderId: current.orderId.toString(),
      country: order.selectedCountry.isoValue,
      method: current.method,
      email: current.email,
    }
    if (order?.selectedCentersAndApps) {
      data.contactPhone = current.mobile
    } else {
      data.mobile = current.mobile
    }
    try {
      const {newUuid, shortenedUrl} = await run(
        client(
          !order?.selectedCentersAndApps
            ? 'orders/process/resend-link'
            : 'cc/order/resend-link',
          {data, method: 'PATCH'},
        ),
      )
      if (newUuid) {
        toast.success(`${current.method} resent`)
        return {updatedUuid: newUuid, shortenedUrl}
      } else {
        toast.error('error resending link')
      }
    } catch (error) {
      setProcessing(false)
      toast.error('Error resending link')
    }
  }

  const checkClientEmailStatus = async current => {
    const data = {
      email: current.email,
    }

    try {
      const result = await run(
        client(`orders/email-status/${data.email}`, {method: 'GET'}),
      )

      if (result.haswarningorError) {
        toast.error(result.error_message)
      }
      if (
        result.haswarningorError === false &&
        result.show_error_msg === false
      ) {
        if (result.show_send_sms_btn) {
          setShwoSmsOption(true)
        }
      } else {
        setShwoSmsOption(false)
      }
    } catch (err) {
      toast.error(err.error)
    }
  }

  return (
    <Container frontendSideNav={frontendSideNav}>
      <MainContainer>
        <WizardContainer>
          <StepsContainer>
            {currentStep === 1 && (
              <CountrySelection nextStep={nextStep} prevStep={prevStep} />
            )}
            {currentStep === 2 && (
              <IndustryCategorySelection
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry.isoValue}
              />
            )}
            {currentStep === 3 && (
              <IndustrySelection
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry}
                selectedIndustry={order.industryCategoryId}
                industryCategoryType={order.industryCategoryType}
              />
            )}
            {currentStep === 4 && (
              <AccountInformation
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry.isoValue}
                billingPreference={order.billingPreference}
                selectedPlan={order.selectedPlan}
                industryCategoryType={order.industryCategoryType}
                industryTemplate={order.template}
                order={order}
                currentStep={currentStep}
                showBackButton={false}
                isEaidFound={isEaidFound}
                setIsEaidFound={setIsEaidFound}
              />
            )}
            {currentStep === 5 && (
              <Catalog
                nextStep={nextStep}
                prevStep={prevStep}
                selectedCountry={order.selectedCountry.isoValue}
                CCAccountInfo={order.CCAccountInfo}
                industryTemplate={order.template}
                prevCentersAndApps={order?.selectedCentersAndApps}
                prevCode={order?.code}
                ccCouponInfo={ccCouponInfo}
                setCcCouponInfo={setCcCouponInfo}
                setDiscountType={setDiscountType}
                discountType={discountType}
                setOrderCouponCodes={setOrderCouponCodes}
                orderCouponCodes={orderCouponCodes}
                setIncentiveCoupon={setIncentiveCoupon}
                all5PlanPrices={all5PlanPrices}
                setIsEaidFound={setIsEaidFound}
                isEaidFound={isEaidFound}
                cohortEaid={cohortEaid}
                setCohortEaid={setCohortEaid}
                resetAutoCoupons={resetAutoCoupons}
                accountInfo={order.accountInformation}
                setAccountInfo={setAccountInfo}
                updateAddressInfo={updateAddressInfo}
                setAccountSubscriptionData={setAccountSubscriptionData}
                accountSubscriptionData={accountSubscriptionData}
                order={order}
                setAllowInvoice={setAllowInvoice}
                allowInvoice={allowInvoice}
                isCohort={isCohort}
                setIsCohort={setIsCohort}
              />
            )}
            {currentStep === 6 && (
              <OnboardingProgram
                nextStep={nextStep}
                prevStep={prevStep}
                selectedPlan={order.selectedPlan}
                billingPreference={order.billingPreference}
                accountInformation={order.accountInformation}
                industryCategoryType={order.industryCategoryType}
                selectedCountry={order.selectedCountry}
                selectedAddons={order.selectedAddons}
                setCreativeApptId={setCreativeApptId}
                setKickoffApptId={setKickoffApptId}
                kickoffApptId={kickoffApptId}
                creativeApptId={creativeApptId}
                onboardingCallDate={onboardingCallDate}
                setOnboardingCallDate={setOnboardingCallDate}
                creativeCallDate={creativeCallDate}
                setCreativeCallDate={setCreativeCallDate}
                allSelectedPurchaseIntent={allSelectedPurchaseIntent}
                setAllSelectedPurchaseIntent={setAllSelectedPurchaseIntent}
                currentStep={currentStep}
                setCartProducts={setCartProducts}
                cartProducts={cartProducts}
                selectedIndustryId={order.industryCategoryId}
                selectedCentersAndApps={order?.selectedCentersAndApps}
                country={order.selectedCountry.isoValue}
                orderCouponCodes={orderCouponCodes}
                setOrderCouponCodes={setOrderCouponCodes}
                discountType={discountType}
                incentiveCoupon={incentiveCoupon}
                setIncentiveCoupon={setIncentiveCoupon}
                upgradeAccountInfo={accountInfo}
                autoCouponInfo={autoCouponInfo}
                ccCouponInfo={ccCouponInfo}
                isCohort={isCohort}
              />
            )}
            {currentStep === 7 && (
              <PaymentDetails
                resendPaymentLink={resendPaymentLink}
                checkClientEmailStatus={checkClientEmailStatus}
                showSmsOption={showSmsOption}
                setShwoSmsOption={setShwoSmsOption}
                nextStep={nextStep}
                prevStep={prevStep}
                selectedAddons={order.selectedAddons}
                selectedCountry={order.selectedCountry.value}
                accountInformation={order.accountInformation}
                planCode={order.package}
                countryIsoValue={order.selectedCountry.isoValue}
                creativeApptId={creativeApptId}
                kickoffApptId={kickoffApptId}
                setKickoffApptId={setKickoffApptId}
                setCreativeApptId={setCreativeApptId}
                setOrderCouponCodes={setOrderCouponCodes}
                orderCouponCodes={orderCouponCodes}
                setCartProducts={setCartProducts}
                accountInfo={accountInfo}
                cartProducts={cartProducts}
                currentStep={currentStep}
                processing={processing}
                setProcessing={setProcessing}
                setCreativeCallDate={setCreativeCallDate}
                setOnboardingCallDate={setOnboardingCallDate}
                selectedPlan={order.selectedPlan}
                clientEmail={order.accountInformation.email}
                selectedCentersAndApps={order?.selectedCentersAndApps}
                sendCCPaymentLink={sendCCPaymentLink}
                ccCouponInfo={ccCouponInfo}
                onboardingCallDate={onboardingCallDate}
                creativeCallDate={creativeCallDate}
                incentiveCoupon={incentiveCoupon}
                discountType={discountType}
                isSEOIncluded={isSEOIncluded}
                autoCouponInfo={autoCouponInfo}
                allowInvoice={allowInvoice}
                setSelectedPaymentMethod={setSelectedPaymentMethod}
                selectedPaymentMethod={selectedPaymentMethod}
                isCohort={isCohort}
                cohortEaid={cohortEaid}
              />
            )}
            {currentStep === 8 && (
              <Commitment
                prevStep={prevStep}
                clientInitials={clientInitials}
                updateInitials={updateInitials}
                purchaseIntents={allSelectedPurchaseIntent}
                onboardingCallDate={onboardingCallDate}
                creativeCallDate={creativeCallDate}
                clientEmail={order.accountInformation.email}
              />
            )}
          </StepsContainer>
        </WizardContainer>
      </MainContainer>
    </Container>
  )
}

export {NewOrder}
