import React, {useState, useEffect, useCallback, useRef} from 'react'
import {useAsync} from '../utils/use-async'
import SkeletonLoader from '../components/skeleton-loader'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuthClient} from '../utils/use-auth-client'
import {UpgradePackageSelection} from './upgrade-package-selection'
import {Catalog} from './catalog'
import {
  MainContainer,
  WizardContainer,
  StepsContainer,
} from '../components/ui/new-order'
import {UpgradeAddonsSelection} from './upgrade-addons-selection'
import {OnboardingProgram} from './onboarding-program'
import PaymentDetails from './payment-details'
import {Commitment} from './commitment.js'
import {ADD_ON} from '../utils/addons-data'
import {toast} from 'react-toastify'
import {
  COUNTRIES,
  CURRENCYS,
  CC_CURRENCIES,
  CC_PLANS,
  MC_PLANS,
  BC_PLANS,
} from '../utils/constants'
import {useUserRoles} from '../utils/use-user-roles'
import {TSSConfirmation} from './tss-confirmation'
import {CountrySelection} from './country-selection'
import {getAuthByRole} from '../utils/get-screen-auth-by-role'
import {useTrackLR} from '../utils/use-track-lr'
import {ADDONS_TYPES} from '../utils/constants'
import {useFlags} from 'launchdarkly-react-client-sdk'
import useAutoCoupons from '../utils/use-auto-coupons'
import {getUpgradeIncentivePlans} from '../utils/incentives'
import useDebounce from '../utils/useDebounce'
import {InvoiceInformation} from '../components/account-information-components/invoice-information'
import {NewOrderLayout} from '../components/new-order-layout.js'

const TOTAL_STEPS = 5

const isObjectEmpty = obj => Object.keys(obj).length === 0

const getPlanCode = (currentPlan, selectedPlan, billingPreference) => {
  if (selectedPlan === 'marketingCenter' || selectedPlan === 'thryvLeads') {
    return currentPlan.planCode
  }
  return `${selectedPlan}-4-dexyp-${
    billingPreference === 'monthly' ? 'm2m' : 'semi'
  }`
}

function Container({children, frontendSideNav}) {
  if (frontendSideNav) {
    return <div className="d-flex">{children}</div>
  }
  return children
}

function UpgradeOrder({selectedCountry}) {
  const navigate = useNavigate()
  const {state, pathname} = useLocation()
  const [order, setOrder] = useState(state?.order || {})
  const [accountInfo, setAccountInfo] = useState({})
  const [accountSubscriptionData, setAccountSubscriptionData] = useState({})
  const [currentStep, setCurrentStep] = useState(1)
  const [allSelectedPurchaseIntent, setAllSelectedPurchaseIntent] = useState({
    firstPurchaseIntent: {},
    secondPurchaseIntent: {},
    thirdPurchaseIntent: {},
    csmExtraInfo: '',
  })
  const [onboardingCallDate, setOnboardingCallDate] = useState(null)
  const [creativeCallDate, setCreativeCallDate] = useState(null)
  const [processing, setProcessing] = useState(false)
  const [isLoading, setIsLoading] = useState(order.v5upgrade ? false : true)
  const [kickoffApptId, setKickoffApptId] = useState(null)
  const [creativeApptId, setCreativeApptId] = useState(null)
  const [orderCouponCodes, setOrderCouponCodes] = useState([])
  const [cartProducts, setCartProducts] = useState([])
  const [clientInitials, setClientInitials] = useState()
  const [ccCouponInfo, setCcCouponInfo] = useState()
  const [incentiveCoupon, setIncentiveCoupon] = useState(false)
  const [discountType, setDiscountType] = useState('Existing Client')
  const [ccUpgradeCoupons, setCcUpgradeCoupons] = useState(null)
  const [upgradePaymentPreview, setUpgradePaymentPreview] = useState(null)
  const [previewRequestFinished, setPreviewRequestFinished] = useState(false)
  const [all5PlanPrices, setAll5PlanPrices] = useState(null)
  const [isInvoiceBilled, setIsInvoiceBilled] = useState(false)
  const [isEaidFound, setIsEaidFound] = useState(false)
  const [cohortEaid, setCohortEaid] = useState(false)
  const [allowInvoice, setAllowInvoice] = useState(false)
  const [isCohort, setIsCohort] = useState(false)
  const [showSmsOption, setShwoSmsOption] = useState(false)
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState('Credit Card')
  const defaultCentersAndApps = useRef()
  const previewToastShown = useRef(false)
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })
  //const STARTER_PACKAGE_ADDON_CODES = ['addon-5-mcstartgr', 'addon-5-mcexpangr']
  const {roles} = useUserRoles()
  const {frontendPreviewPayment, frontendSideNav, frontendListingsBcMc} =
    useFlags()
  const customEvent = {
    key: 'upgrade-order',
    ...(accountInfo.recurlyEmail && {companyEmail: accountInfo.recurlyEmail}),
    ...(accountInfo.recurlyCompany && {
      companyName: accountInfo.recurlyCompany,
    }),
    ...(accountInfo.thryvID && {thryvId: accountInfo.thryvID}),
  }
  useTrackLR(customEvent)
  const isCCPlan = accountInfo?.planCode?.includes('-5-') || order?.v5upgrade
  const selectedPlanCode =
    !isCCPlan &&
    `${order.selectedPlan?.name}-4-dexyp-${
      order.billingPreference?.value === 'monthly' ? 'm2m' : 'semi'
    }`
  const isUpgrade = pathname === '/order/upgrade'
  const eligibleTransitionCenters =
    isUpgrade &&
    order.selectedCentersAndApps &&
    getUpgradeIncentivePlans(
      order.selectedCentersAndApps?.centers,
      defaultCentersAndApps.current?.centers,
      upgradePaymentPreview,
    )
  const {autoCouponInfo, resetAutoCoupons} = useAutoCoupons({
    discountType,
    selectedPackage: selectedPlanCode,
    selectedCountry: COUNTRIES[accountInfo.billingAddress?.country],
    selectedCentersAndApps: order.selectedCentersAndApps,
    eligibleTransitionCenters,
    selectedAddons: order.selectedAddons,
    upgradeAccountInfo: accountInfo,
    incentiveCoupon,
    setIncentiveCoupon,
    currentStep,
    setOrderCouponCodes,
    ccCouponInfo,
    upgradePaymentPreview,
    isEaidFound,
    isCohort,
  })

  const hasWebsiteAddon = addons => addons?.some(({name}) => name === 'Website')

  const isWebsiteIncluded =
    hasWebsiteAddon(accountInfo?.addOns) ||
    hasWebsiteAddon(accountInfo?.legacyAddOns)
  const isSocialBoostIncluded = accountInfo?.addOns?.some(
    ({name}) => name === ADD_ON.entitlements['Social Boost'].label,
  )
  const isSEOIncluded = order?.selectedCentersAndApps?.centers?.some(
    ({name, alreadyIncluded}) => name.includes('SEO') && !alreadyIncluded,
  )

  const getGmailItems = () => {
    let result = []
    const gmailStandard = accountInfo?.addOns?.find(
      ({name}) => name === 'Gmail Standard',
    )
    const gmailStandardFree = accountInfo?.addOns?.find(
      ({name}) => name === 'Gmail Standard Free',
    )
    const gmailCertified = accountInfo?.addOns?.find(
      ({name}) => name === 'Gmail Certified',
    )
    if (gmailStandard) {
      result.push(gmailStandard)
    }
    if (gmailStandardFree) {
      result.push(gmailStandardFree)
    }
    if (gmailCertified) {
      result.push(gmailCertified)
    }
    return result
  }

  const isTSS = getAuthByRole(['tss'], roles)
  const isAus = selectedCountry === 'AU'
  const isNZ = selectedCountry === 'NZ'
  const isClientCare = getAuthByRole(['clientcare'], roles)
  const isReseller = getAuthByRole(['reseller'], roles)

  useEffect(() => {
    const get5PlanPrices = async () => {
      const url = 'plans/5/list'
      const response = await client(url)
      setAll5PlanPrices(response)
    }

    get5PlanPrices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (kickoffApptId) {
      return () => {
        client(`plans/onboarding`, {
          data: {
            type: 'onboarding',
            country: accountInfo?.billingAddress?.country,
            demoApptId: kickoffApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kickoffApptId])

  useEffect(() => {
    if (creativeApptId) {
      return () => {
        client(`plans/creative`, {
          data: {
            type: 'creative',
            country: accountInfo?.billingAddress?.country,
            demoApptId: creativeApptId,
          },
          method: 'DELETE',
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creativeApptId])

  const nextStep = data => {
    setOrder({...order, ...data})
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }

  const prevStep = () => {
    let _currentStep = currentStep
    if (currentStep <= 1) {
      _currentStep = 1
      navigate(!isReseller ? '/all-accounts' : '/my-accounts')
    } else {
      _currentStep = currentStep - 1
    }
    setCurrentStep(_currentStep)
  }

  const updateInitials = initials => {
    setClientInitials(initials)
  }

  const fetchAccountInfo = useCallback(async () => {
    if (order?.v5upgrade) return
    try {
      let url = `subscription/uuid-${order.billingAccountId}`
      const response = await client(url)
      response.thryvID = response.thryvID || order.thryvId
      const emailValidation = await client(
        `orders/validate-email/${response.recurlyEmail}`,
      )
      if (emailValidation.isInvoiceBilled) setIsInvoiceBilled(true)
      if (isCCPlan) {
        setOrder({
          ...order,
          CCAccountInfo: {thryvId: response.thryvID},
          ...(!order.template && {template: response.industry}),
        })
      }
      setAccountInfo(response)
      if (!response?.billingAddress?.country) {
        setCurrentStep(0)
      }
      setIsLoading(false)
    } catch (err) {
      if (err.status === 404) {
        toast.error(err.message)
        navigate(-1)
      }
      if (err.status !== 404) throw err
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client, order.billingAccountId, order.thryvId, isCCPlan])

  const sendPaymentLink = async (contactMethod, contactInfo) => {
    const intents = !order.onBoardingProgramData.intents.every(isObjectEmpty)
      ? order.onBoardingProgramData.intents.map(intent => intent.option)
      : []
    const altPlanCodes = [
      ...ADD_ON.add_ons['Marketing Center Plus'].addOnCodes,
      ...ADD_ON.add_ons['Thryv Leads'].addOnCodes,
    ]
    const addonPlanCodes = order.selectedAddons
      .filter(({product}) => product !== 'One Time Design Fee')
      .filter(({planCode}) => planCode)
      .filter(({planCode}) => !planCode.includes('dexyp'))
      .filter(({planCode}) => !planCode.includes('-setupfee'))
      .filter(
        ({type, planCode}) =>
          !(
            type === ADDONS_TYPES['SELECTED_PLAN'] &&
            altPlanCodes.includes(planCode)
          ),
      )
      .map(({planCode, quantity = 1}) => ({
        addonPlanCode: planCode,
        quantity,
      }))

    const websiteQty =
      order.selectedAddons.find(
        addon =>
          addon.product === ADD_ON.one_time['5 Extra Website Pages'].label,
      )?.quantity || 0
    const thryvLeadsId = order.selectedAddons.find(addon => addon.thryvLeadsId)
    const thryvLeadBillingDate = order.selectedAddons.find(
      addon => addon.thryvLeadBillingDate,
    )
    const seoProposalId = order.selectedAddons.find(
      addon => addon.seoProposalId,
    )
    const seoBillingDate = order.selectedAddons.find(
      addon => addon.seoBillingDate,
    )
    const mcAddon = order.selectedAddons.find(
      x => x.product === ADD_ON.add_ons['Marketing Center Plus'].label,
    )
    const webSiteDomain = order.selectedAddons.find(
      addon => addon.sensisSiteDomain,
    )
    const subchannel = order.subchannel
    const leadId = order.leadId
    const selectedPlanCode = getPlanCode(
      {planCode: accountInfo.planCode, planName: accountInfo.planName},
      order.selectedPlan.name,
      order.billingPreference.value,
    )

    const data = {
      firstName: accountInfo.recurlyFirst,
      lastName: accountInfo.recurlyLast,
      email: accountInfo.recurlyEmail,
      package: selectedPlanCode,
      company: accountInfo.recurlyCompany,
      postCode: accountInfo.billingAddress.postalCode,
      address: accountInfo.recurlyStreet,
      city: accountInfo.billingAddress.city,
      state: accountInfo.billingAddress.region,
      country: accountInfo.billingAddress.country,
      curr: CURRENCYS[COUNTRIES[accountInfo.billingAddress.country].value],
      mobile: accountInfo.recurlyPhone,
      companyPhone: accountInfo.billingAddress.phone,
      software: order.selectedPlan.industryType,
      purchasePlanCode: selectedPlanCode,
      addonPlanCodes,
      intents,
      contact: {
        sendLinkVia: isTSS || isClientCare ? 'none' : contactMethod,
      },
      isSync: accountInfo?.product?.toLowerCase() === 'thryv' ? false : true,
      planType: order.billingPreference.value === 'monthly' ? 'm2m' : 'semi',
      currentPlanCode: accountInfo.planCode,
      thryvId: accountInfo.thryvID,
      recurlyAccountId: accountInfo.recurlyAccountId,
      orderNotes: order.onBoardingProgramData.csmExtraInfo,
    }
    if (accountInfo?.industry) {
      data.industryId = accountInfo.industry
    }
    if (thryvLeadsId) {
      data.thryvLeadsId = thryvLeadsId.thryvLeadsId
    }
    if (thryvLeadBillingDate) {
      data.thryvLeadsDate = thryvLeadBillingDate.thryvLeadBillingDate
    }
    if (seoProposalId) {
      data.seoProposalId = seoProposalId.seoProposalId
    }
    if (seoProposalId && seoProposalId.seoDomain) {
      data.seoDomain = seoProposalId.seoDomain
    }
    if (seoBillingDate) {
      data.seoDate = seoBillingDate.seoBillingDate
    }
    if (mcAddon) {
      data.sensisSiteDomain = mcAddon.sensisSiteDomain
    } else if (webSiteDomain) {
      data.sensisSiteDomain = webSiteDomain.sensisSiteDomain
    }
    if (subchannel) {
      data.subchannel = subchannel
    }
    if (leadId) {
      data.leadId = leadId
    }
    if (websiteQty > 0) {
      data.websiteQty = websiteQty
    }
    if (kickoffApptId) {
      data.onboardingApptId = kickoffApptId
    }
    if (creativeApptId) {
      data.creativeApptId = creativeApptId
    }
    if (order?.xpCode) {
      data.xpCode = order.xpCode
    }
    if (order?.differentSalesRep?.value) {
      data.behalf = order.salesRepCode
      if (order.behalfFirst) {
        data.behalfFirst = order.behalfFirst
      }
      if (order.behalfLast) {
        data.behalfLast = order.behalfLast
      }
      if (order.behalfEmail) {
        data.behalfEmail = order.behalfEmail
      }
    }
    if (order?.overrideRep?.value) {
      data.overrideRepId = order.overrideRepCode
      if (order.overrideRepFirst) {
        data.overrideRepFirstName = order.overrideRepFirst
      }
      if (order.overrideRepLast) {
        data.overrideRepLastName = order.overrideRepLast
      }
      if (order.overrideRepEmail) {
        data.overrideRepEmail = order.overrideRepEmail
      }
    }
    if (order.clientId) {
      data.enterpriseId = order.clientId
    }
    if (contactMethod === 'sms') {
      data.contact.mobile = contactInfo
    } else if (contactMethod === 'email') {
      data.contact.email = contactInfo
    }
    const COUPON_CODES = orderCouponCodes.map(coupon => {
      return coupon.code
    })
    if (COUPON_CODES.length > 0) {
      data.couponCodes = COUPON_CODES
    }
    if ('sensisSiteDomain' in data && !data.sensisSiteDomain) {
      delete data.sensisSiteDomain
    }
    if (selectedPaymentMethod) {
      data.isInvoiceBilled = selectedPaymentMethod === 'Invoice'
    }
    if (cohortEaid) {
      data.cohortEaid = cohortEaid
    }
    if (allowInvoice && selectedPaymentMethod !== 'Invoice') {
      data.updateToAutopay = true
    }

    try {
      const result = await run(
        client('orders/process/upgrade/send-link', {data}),
      )
      if (result.message === 'sms sent' || result.message === 'email sent') {
        toast.success(result.message)
      } else {
        toast.error(result.message)
      }
      if (result.randomId) {
        return result
      }
    } catch (error) {
      setProcessing(false)
      if (error.status !== 400) {
        throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(error.message)
      }
    }
  }

  const findWebsiteEntitlement = centers =>
    centers
      .find(({entitlements}) =>
        entitlements.some(
          ({name}) => name === 'Professionally Designed Website',
        ),
      )
      ?.entitlements.find(
        ({name}) => name === 'Professionally Designed Website',
      )

  const findWebsiteApp = ({name}) => name === 'Professionally Designed Website'

  const formatCenters = (centers, callbackFn) =>
    centers
      .filter(callbackFn)
      .map(({code, quantity = 1, entitlements, startDate}) => ({
        code,
        quantity,
        ...(startDate && {startDate}),
        addons: entitlements.map(({code, quantity = 1}) => ({code, quantity})),
      }))

  const sendCCPaymentLink = async (contactMethod, contactInfo) => {
    const {centers, apps, oneTimes} = order.selectedCentersAndApps
    const thryvLeadsId = centers.find(
      ({name}) => name === 'Thryv Leads',
    )?.thryvLeadsId
    const seoProposal = centers.find(({name}) => name.includes('SEO'))
    const sensisDomain = (
      findWebsiteEntitlement(centers) || apps.find(findWebsiteApp)
    )?.sensisSiteDomain
    const mcDomain = centers.find(({name}) =>
      name.toLowerCase().includes('marketing'),
    )?.mcDomain
    const parentSubscriptions = formatCenters(centers, ({name}) =>
      name.includes('Command Center'),
    )
    const marketplace = apps.find(({name}) => name === 'Thryv Marketplace')
    const isBCorMCInCart = centers.some(
      ({code}) => MC_PLANS.includes(code) || BC_PLANS.includes(code),
    )
    let childSubscriptions = []
    for (let center of centers) {
      if (center?.name.includes('Command Center')) {
        continue
      } else {
        const formattedCenter = formatCenters(centers, ({name}) =>
          name.includes(center?.name),
        )
        childSubscriptions.push(formattedCenter[0])
      }
    }

    childSubscriptions.push({
      code: marketplace.code,
      quantity: 1,
      addons: apps
        .filter(({name, code}) => {
          const isListingsApp = code === 'app-listings'
          if (isListingsApp && isBCorMCInCart && frontendListingsBcMc)
            return false
          return name !== 'Thryv Marketplace'
        })
        .map(({code, quantity = 1}) => {
          return {
            code,
            quantity,
          }
        }),
    })
    const oneTimeFees = oneTimes.map(({code, quantity = 1}) => {
      return {
        code,
        quantity,
      }
    })

    const data = {
      firstName: accountInfo.recurlyFirst,
      lastName: accountInfo.recurlyLast,
      email: accountInfo.recurlyEmail,
      company: accountInfo.recurlyCompany,
      currency:
        CC_CURRENCIES[COUNTRIES[accountInfo.billingAddress.country].value],
      industryId: order.template || accountInfo.industry,
      businessAddress: {
        street: accountInfo.billingAddress.street1,
        city: accountInfo.billingAddress.city,
        state: accountInfo.billingAddress.state,
        postalCode:
          accountInfo.billingAddress.postalCode ||
          accountInfo.billingAddress.zipCode,
        country: accountInfo.billingAddress.country,
        phone: accountInfo.billingAddress.phone,
      },
      contactPhone: contactInfo,
      thryvId: order.CCAccountInfo.thryvId || accountInfo.thryvID,
      flow: 'upgrade',
      sendLinkVia: contactMethod,
      parentSubscriptions,
      childSubscriptions,
      oneTimeFees,
      coupons: ccUpgradeCoupons || [],
      isCohort,
    }
    if (cohortEaid) {
      data.cohortEaid = cohortEaid
    }
    if (accountInfo?.salesRepCode) {
      data.behalfRepId = accountInfo.salesRepCode
      if (accountInfo.behalfFirst) {
        data.behalfFirstName = accountInfo.behalfFirst
      }
      if (accountInfo.behalfLast) {
        data.behalfLastName = accountInfo.behalfLast
      }
      if (accountInfo.behalfEmail) {
        data.behalfEmail = accountInfo.behalfEmail
      }
    }
    if (accountInfo?.overrideRepCode) {
      data.overrideRepId = accountInfo.overrideRepCode
      if (accountInfo.overrideRepFirst) {
        data.overrideRepFirst = accountInfo.overrideRepFirst
      }
      if (accountInfo.overrideRepLast) {
        data.overrideRepLast = accountInfo.overrideRepLast
      }
      if (accountInfo.overrideRepEmail) {
        data.overrideRepEmail = accountInfo.overrideRepEmail
      }
    }
    if (accountInfo?.xpCode) {
      data.xpCode = accountInfo.xpCode
    }
    if (['AU', 'NZ'].includes(accountInfo.billingAddress.country)) {
      data.abn = accountInfo.abn.replaceAll(' ', '')
      if (!data.abn.length) delete data.abn
    }
    if (kickoffApptId) {
      data.onboardingApptId = kickoffApptId
    }
    if (creativeApptId) {
      data.creativeApptId = creativeApptId
    }
    if (thryvLeadsId) {
      data.thryvLeadsId = thryvLeadsId
    }
    if (seoProposal) {
      data.seoProposalId = seoProposal.seoProposalId
      data.seoDomain = seoProposal.seoDomain
    }
    if (sensisDomain) {
      data.sensisDomain = sensisDomain
    }
    if (mcDomain) {
      data.mcDomain = mcDomain
    }
    if (contactMethod === 'tss') {
      data.tssUpgrade = true
    }
    if (selectedPaymentMethod) {
      data.isInvoiceBilled = selectedPaymentMethod === 'Invoice'
    }
    if (allowInvoice && selectedPaymentMethod !== 'Invoice') {
      data.updateToAutopay = true
    }

    try {
      const result = await run(client('cc/order/send-link', {data}))
      if (
        result.message === 'Payment link sent' ||
        result.message === 'Order created'
      ) {
        toast.success(result.message)
      } else {
        toast.error(result.message)
      }
      if (result.paymentUuid) {
        return result
      }
    } catch (error) {
      setProcessing(false)
      if (error.status !== 400) {
        toast.error(`Error sending link: ${error.message}`)
        throw error
      }
      if (error.errors) {
        error.errors.forEach(({message}) => toast.error(message))
      } else {
        toast.error(`Error sending link: ${error.message}`)
      }
    }
  }

  const resendPaymentLink = async current => {
    try {
      const data = {
        orderId: current.orderId.toString(),
        country: COUNTRIES[accountInfo.billingAddress.country].isoValue,
        method: current.method,
        email: current.email,
      }
      if (isCCPlan) {
        data.contactPhone = current.mobile
      } else {
        data.mobile = current.mobile
      }
      const {newUuid, shortenedUrl} = await run(
        client(
          !isCCPlan ? 'orders/process/resend-link' : 'cc/order/resend-link',
          {data, method: 'PATCH'},
        ),
      )
      if (newUuid) {
        toast.success('Payment Link resent ')
        return {updatedUuid: newUuid, shortenedUrl}
      } else {
        toast.error('Error resending link')
      }
    } catch (error) {
      toast.error('Error resending link')
      throw error
    }
  }

  const checkClientEmailStatus = async current => {
    const data = {
      email: current.email,
    }

    try {
      const result = await run(
        client(`orders/email-status/${data.email}`, {method: 'GET'}),
      )

      if (result.haswarningorError) {
        toast.error(result.error_message)
      }
      if (
        result.haswarningorError === false &&
        result.show_error_msg === false
      ) {
        if (result.show_send_sms_btn) {
          setShwoSmsOption(true)
        }
      } else {
        setShwoSmsOption(false)
      }
    } catch (err) {
      toast.error(err.error)
    }
  }

  const updateAddressInfo = (data, setShowUpdateAddressModal) => {
    let saveData = {
      ...accountInfo,
      recurlyFirst: data.firstName,
      recurlyLast: data.lastName,
      recurlyCompany: data.company,
      salesRepCode: data.salesRepCode,
      salesRepInfo: data.salesRepInfo,
      behalfEmail: data.behalfEmail,
      behalfFirst: data.behalfFirst,
      behalfLast: data.behalfLast,
      xpCode: data.xpCode,
      overrideRepInfo: data.overrideRepInfo,
      overrideRepCode: data.overrideRepCode,
      overrideRepEmail: data.overrideRepEmail,
      overrideRepFirst: data.overrideRepFirst,
      overrideRepLast: data.overrideRepLast,
      abn: data.abn,
      billingAddress: {
        ...accountInfo.billingAddress,
        street1: data.street1,
        city: data.city,
        state: data.region,
        zipCode: data.postalCode,
        phone: data.phone,
        mobile: data.mobile,
      },
    }
    if (!isAus && !isNZ) {
      delete saveData.abn
    }
    if (!data.salesRepCode) {
      delete saveData.salesRepInfo
      delete saveData.salesRepCode
      delete saveData.behalfEmail
      delete saveData.behalfFirst
      delete saveData.behalfLast
    }
    if (!data.overrideRepCode) {
      delete saveData.overrideRepInfo
      delete saveData.overrideRepCode
      delete saveData.overrideRepEmail
      delete saveData.overrideRepFirst
      delete saveData.overrideRepLast
    }
    setAccountInfo(saveData)
  }

  const updateBillingAddress = data => {
    if (data.selectedCountry) {
      let _currentStep = currentStep
      if (_currentStep === 0) {
        _currentStep = 1
      }
      setAccountInfo({
        ...accountInfo,
        billingAddress: {
          ...accountInfo.billingAddress,
          country: data.selectedCountry.isoValue,
        },
      })
      setCurrentStep(_currentStep)
      return
    }

    const newAccountInfo = {
      ...accountInfo,
      billingAddress: {
        ...accountInfo.billingAddress,
        street1: data.streetAddress || data.street1,
        city: data.city,
        region: data.state || data.region,
        postalCode: data.zipCode || data.postalCode,
      },
    }

    if (data.streetAddress || data.street1) {
      newAccountInfo.recurlyStreet = data.streetAddress || data.street1
    }
    if (data.phone) {
      newAccountInfo.billingAddress.phone = data.phone
    }
    if (data.company) {
      newAccountInfo.recurlyCompany = data.company
    }
    if (data.firstName) {
      newAccountInfo.recurlyFirst = data.firstName
    }
    if (data.lastName) {
      newAccountInfo.recurlyLast = data.lastName
    }
    if (data.mobile) {
      newAccountInfo.recurlyPhone = data.mobile
    }
    setAccountInfo(newAccountInfo)
  }

  const getPaymentPreview = async () => {
    const {
      selectedCentersAndApps: {apps, centers, oneTimes},
    } = order
    const isMCInCart = centers.some(({code}) => MC_PLANS.includes(code))
    const isBCInCart = centers.some(({code}) => BC_PLANS.includes(code))
    const parentSubscriptions = formatCenters(centers, ({name}) =>
      name.includes('Command Center'),
    )
    const marketplace = apps.find(({name}) => name === 'Thryv Marketplace')
    let childSubscriptions = []
    for (let center of centers) {
      if (center?.name.includes('Command Center')) {
        continue
      } else {
        const formattedCenter = formatCenters(centers, ({name}) =>
          name.includes(center?.name),
        )
        childSubscriptions.push(formattedCenter[0])
      }
    }

    childSubscriptions.push({
      code: marketplace.code,
      quantity: 1,
      addons: apps
        .filter(({name, code}) => {
          const isListingsApp = code === 'app-listings'
          if (
            isListingsApp &&
            (isMCInCart || isBCInCart) &&
            frontendListingsBcMc
          )
            return false
          return name !== 'Thryv Marketplace'
        })
        .map(({code, quantity = 1}) => {
          return {
            code,
            quantity,
          }
        }),
    })
    const oneTimeFees = oneTimes.map(({code, quantity = 1}) => {
      return {
        code,
        quantity,
      }
    })
    const currency =
      CC_CURRENCIES[COUNTRIES[accountInfo.billingAddress.country].value]
    const data = {
      childSubscriptions,
      parentSubscriptions,
      oneTimeFees,
      thryvId: accountInfo.thryvID || order.CCAccountInfo.thryvId,
      currency,
      coupons: ccUpgradeCoupons,
    }
    if (!previewToastShown.current) {
      try {
        const response = await client('cc/order/preview-payment', {data})
        setUpgradePaymentPreview(response)
        if (ccUpgradeCoupons) {
          const isTransitionIncentiveCoupon = ccUpgradeCoupons.some(x =>
            x.includes('25month1', '25month2', '25month3'),
          )
          response['isTransitionIncentiveCoupon'] = isTransitionIncentiveCoupon
          setUpgradePaymentPreview(response)
        }
        setPreviewRequestFinished(true)
      } catch (err) {
        if (err.message === 'Billing info missing on account') {
          toast.warning(
            'Payment Preview Unavailable: Billing info missing on account',
          )
          previewToastShown.current = true
          setUpgradePaymentPreview(null)
          setPreviewRequestFinished(true)
        }
      }
    }
  }

  const debouncedOrderCouponCodes = useDebounce(orderCouponCodes, 500)

  const getUpgradeCouponData = useCallback(async () => {
    let upgradeCoupons = []
    const transitionCouponCodes = ['25month1', '25month2', '25month3']
    const redeemedCoupons = await client(
      `accounts/5/coupon-redemptions?thryvId=${
        order.CCAccountInfo.thryvId || accountInfo.thryvID
      }`,
    )
    const hasTransitionCoupons = redeemedCoupons.some(({code}) =>
      transitionCouponCodes.includes(code),
    )
    const transitionApplies =
      defaultCentersAndApps.current.centers.filter(
        ({name}) =>
          !name.includes('SEO') &&
          name !== 'Thryv Leads' &&
          !name.includes('Basic'),
      ).length === 0 && !hasTransitionCoupons

    if (
      orderCouponCodes.find(x => x.code === 'transition75') &&
      transitionApplies
    ) {
      const {code} = order.selectedCentersAndApps.centers.find(({name}) =>
        CC_PLANS.some(plan => name.toLowerCase().includes(plan)),
      )
      const createUrl = couponCode =>
        `orders/coupon/validate?couponCode=${couponCode}&country=${accountInfo.billingAddress.country}&planCode=${code}`
      const [transitionCoupon1, transitionCoupon2, transitionCoupon3] =
        await Promise.all(
          transitionCouponCodes.map(async code => {
            const url = createUrl(code)
            return await client(url)
          }),
        )
      const transitionCouponIndex = orderCouponCodes.findIndex(
        addonInfo => addonInfo.code === 'transition75',
      )
      const couponsCopy = [...orderCouponCodes]

      couponsCopy.splice(transitionCouponIndex, 1)
      couponsCopy.push(transitionCoupon1, transitionCoupon2, transitionCoupon3)

      const COUPON_CODES = couponsCopy
        .filter(coupon => {
          return coupon.isValid
        })
        .map(coupon => {
          return coupon.code
        })
      if (COUPON_CODES.length > 0) {
        upgradeCoupons = COUPON_CODES
      }
    } else {
      const ALL_COUPON_CODES = orderCouponCodes
        .filter(coupon => {
          return coupon.isValid && coupon.code !== 'transition75'
        })
        .map(coupon => {
          return coupon.code
        })
      if (ALL_COUPON_CODES.length > 0) {
        upgradeCoupons = ALL_COUPON_CODES
      } else {
        upgradeCoupons = []
      }
    }
    setCcUpgradeCoupons(upgradeCoupons)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedOrderCouponCodes])

  useEffect(() => {
    if (!isUpgrade || !isCCPlan || currentStep < 2) return

    getUpgradeCouponData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUpgradeCouponData])

  useEffect(() => {
    if (
      !frontendPreviewPayment ||
      !isUpgrade ||
      !ccUpgradeCoupons ||
      !order.selectedCentersAndApps ||
      upgradePaymentPreview
    )
      return

    getPaymentPreview()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ccUpgradeCoupons])

  useEffect(() => {
    if (!frontendPreviewPayment) {
      setPreviewRequestFinished(true)
    }
  }, [frontendPreviewPayment])

  useEffect(() => {
    if (order.billingAccountId) fetchAccountInfo()
  }, [fetchAccountInfo, order.billingAccountId])

  useEffect(() => {
    if (ccCouponInfo && orderCouponCodes.length === 0) {
      setOrderCouponCodes([ccCouponInfo])
    }
  }, [ccCouponInfo, orderCouponCodes])

  return (
    <Container frontendSideNav={frontendSideNav}>
      <MainContainer>
        <WizardContainer>
          {isInvoiceBilled ? (
            <StepsContainer>
              <NewOrderLayout
                prevStep={prevStep}
                showContinueButton={false}
                title="Invoices"
                showBackButton={true}
                cohortEaid={cohortEaid}
                setCohortEaid={setCohortEaid}
              >
                <InvoiceInformation
                  thryvId={accountInfo.thryvID}
                  email={accountInfo.recurlyEmail}
                  setIsInvoiceBilled={setIsInvoiceBilled}
                />
              </NewOrderLayout>
            </StepsContainer>
          ) : (
            <>
              {isLoading ? (
                <div className="text-center p-3">
                  <SkeletonLoader width="90%" height="50vh" />
                </div>
              ) : (
                <StepsContainer>
                  {currentStep === 0 && (
                    <CountrySelection nextStep={updateBillingAddress} />
                  )}
                  {currentStep === 1 && !isCCPlan && (
                    <UpgradePackageSelection
                      nextStep={nextStep}
                      prevStep={prevStep}
                      selectedCountry={accountInfo.billingAddress.country}
                      accountInfo={accountInfo}
                      updateBillingAddress={updateBillingAddress}
                    />
                  )}
                  {currentStep === 1 && isCCPlan && order?.CCAccountInfo && (
                    <Catalog
                      nextStep={nextStep}
                      prevStep={prevStep}
                      updateAddressInfo={updateAddressInfo}
                      selectedCountry={accountInfo?.billingAddress?.country}
                      CCAccountInfo={order.CCAccountInfo}
                      industryTemplate={order.template}
                      prevCentersAndApps={order?.selectedCentersAndApps}
                      prevCode={order?.code}
                      ccCouponInfo={ccCouponInfo}
                      setCcCouponInfo={setCcCouponInfo}
                      setAccountInfo={setAccountInfo}
                      setAccountSubscriptionData={setAccountSubscriptionData}
                      accountSubscriptionData={accountSubscriptionData}
                      accountInfo={accountInfo}
                      setCurrentStep={setCurrentStep}
                      order={order}
                      setOrder={setOrder}
                      defaultUpgradeCentersAndApps={defaultCentersAndApps}
                      setDiscountType={setDiscountType}
                      discountType={discountType}
                      setOrderCouponCodes={setOrderCouponCodes}
                      orderCouponCodes={orderCouponCodes}
                      setIncentiveCoupon={setIncentiveCoupon}
                      all5PlanPrices={all5PlanPrices}
                      isEaidFound={isEaidFound}
                      setIsEaidFound={setIsEaidFound}
                      cohortEaid={cohortEaid}
                      setCohortEaid={setCohortEaid}
                      upgradePaymentPreview={upgradePaymentPreview}
                      setUpgradePaymentPreview={setUpgradePaymentPreview}
                      setPreviewRequestFinished={setPreviewRequestFinished}
                      ccUpgradeCoupons={ccUpgradeCoupons}
                      setCcUpgradeCoupons={setCcUpgradeCoupons}
                      resetAutoCoupons={resetAutoCoupons}
                      setAllowInvoice={setAllowInvoice}
                      allowInvoice={allowInvoice}
                      isCohort={isCohort}
                      setIsCohort={setIsCohort}
                    />
                  )}
                  {currentStep === 2 && !isCCPlan && (
                    <UpgradeAddonsSelection
                      nextStep={nextStep}
                      prevStep={prevStep}
                      selectedPlan={order.selectedPlan}
                      billingPreference={order.billingPreference}
                      selectedCountry={
                        COUNTRIES[accountInfo.billingAddress.country].value
                      }
                      currentGmailItems={getGmailItems()}
                      industryCategoryType={order.selectedPlan.industryType}
                      selectedIndustry={accountInfo.industry}
                      accountInfo={accountInfo}
                      accountSubscriptionData={accountSubscriptionData}
                      isWebsiteIncluded={isWebsiteIncluded}
                      all5PlanPrices={all5PlanPrices}
                      isTSS={isTSS}
                      isEaidFound={isEaidFound}
                      cohortEaid={cohortEaid}
                      setIsEaidFound={setIsEaidFound}
                      setCohortEaid={setCohortEaid}
                      setAllowInvoice={setAllowInvoice}
                      setIsCohort={setIsCohort}
                    />
                  )}
                  {((currentStep === 3 && !isCCPlan) ||
                    (currentStep === 2 && isCCPlan)) && (
                    <OnboardingProgram
                      nextStep={nextStep}
                      prevStep={prevStep}
                      selectedPlan={order.selectedPlan}
                      billingPreference={order.billingPreference}
                      accountInformation={{
                        mobile:
                          accountInfo?.billingAddress?.mobile ||
                          accountInfo?.recurlyPhone,
                        email: accountInfo?.recurlyEmail,
                        lastName: accountInfo?.recurlyLast,
                        firstName: accountInfo?.recurlyFirst,
                        country: accountInfo?.billingAddress?.country,
                        state: accountInfo?.billingAddress?.state,
                        city: accountInfo?.billingAddress?.city,
                        address: accountInfo?.billingAddress?.street1,
                        company: accountInfo?.recurlyCompany,
                        selectedPackage: selectedPlanCode,
                      }}
                      industryCategoryType={
                        isCCPlan
                          ? order.template
                          : order.selectedPlan.industryType
                      }
                      selectedAddons={order.selectedAddons}
                      allSelectedPurchaseIntent={allSelectedPurchaseIntent}
                      setAllSelectedPurchaseIntent={
                        setAllSelectedPurchaseIntent
                      }
                      currentPlan={accountInfo.planCode}
                      currentPlanName={accountInfo.planName}
                      setKickoffApptId={setKickoffApptId}
                      kickoffApptId={kickoffApptId}
                      creativeApptId={creativeApptId}
                      setCreativeApptId={setCreativeApptId}
                      setCartProducts={setCartProducts}
                      cartProducts={cartProducts}
                      isWebsiteIncluded={isWebsiteIncluded}
                      isSocialBoostIncluded={isSocialBoostIncluded}
                      onboardingCallDate={onboardingCallDate}
                      setOnboardingCallDate={setOnboardingCallDate}
                      setCreativeCallDate={setCreativeCallDate}
                      creativeCallDate={creativeCallDate}
                      selectedCountry={
                        COUNTRIES[accountInfo.billingAddress.country]
                      }
                      selectedCentersAndApps={order.selectedCentersAndApps}
                      orderCouponCodes={orderCouponCodes}
                      setOrderCouponCodes={setOrderCouponCodes}
                      defaultCentersAndApps={defaultCentersAndApps.current}
                      setIncentiveCoupon={setIncentiveCoupon}
                      incentiveCoupon={incentiveCoupon}
                      discountType={discountType}
                      setDiscountType={setDiscountType}
                      upgradeAccountInfo={accountInfo}
                      ccCouponInfo={ccCouponInfo}
                      ccUpgradeCoupons={ccUpgradeCoupons}
                      upgradePaymentPreview={upgradePaymentPreview}
                      previewRequestFinished={previewRequestFinished}
                      eligibleTransitionCenters={eligibleTransitionCenters}
                      isCohort={isCohort}
                    />
                  )}
                  {((currentStep === 4 && !isCCPlan) ||
                    (currentStep === 3 && isCCPlan)) && (
                    <PaymentDetails
                      sendPaymentLink={sendPaymentLink}
                      resendPaymentLink={resendPaymentLink}
                      checkClientEmailStatus={checkClientEmailStatus}
                      showSmsOption={showSmsOption}
                      setShwoSmsOption={setShwoSmsOption}
                      prevStep={prevStep}
                      nextStep={nextStep}
                      selectedAddons={order.selectedAddons}
                      accountInformation={{
                        mobile:
                          accountInfo.billingAddress.mobile ||
                          accountInfo.recurlyPhone,
                        package: selectedPlanCode,
                      }}
                      planCode={selectedPlanCode}
                      countryIsoValue={accountInfo.billingAddress.country}
                      setOrderCouponCodes={setOrderCouponCodes}
                      orderCouponCodes={orderCouponCodes}
                      setKickoffApptId={setKickoffApptId}
                      kickoffApptId={kickoffApptId}
                      creativeApptId={creativeApptId}
                      setCreativeApptId={setCreativeApptId}
                      accountInfo={accountInfo}
                      selectedPlan={order.selectedPlan}
                      setCartProducts={setCartProducts}
                      cartProducts={cartProducts}
                      processing={processing}
                      setProcessing={setProcessing}
                      selectedCountry={accountInfo.billingAddress.country}
                      setCreativeCallDate={setCreativeCallDate}
                      setOnboardingCallDate={setOnboardingCallDate}
                      clientEmail={accountInfo.recurlyEmail}
                      selectedCentersAndApps={order?.selectedCentersAndApps}
                      sendCCPaymentLink={sendCCPaymentLink}
                      ccCouponInfo={ccCouponInfo}
                      onboardingCallDate={onboardingCallDate}
                      creativeCallDate={creativeCallDate}
                      isCCPlan={isCCPlan}
                      defaultCentersAndApps={defaultCentersAndApps.current}
                      incentiveCoupon={incentiveCoupon}
                      discountType={discountType}
                      isSEOIncluded={isSEOIncluded}
                      autoCouponInfo={autoCouponInfo}
                      upgradePaymentPreview={upgradePaymentPreview}
                      isInvoiceBilled={isInvoiceBilled}
                      allowInvoice={allowInvoice}
                      isCohort={isCohort}
                      setSelectedPaymentMethod={setSelectedPaymentMethod}
                      previewRequestFinished={previewRequestFinished}
                      selectedPaymentMethod={selectedPaymentMethod}
                      cohortEaid={cohortEaid}
                      setCohortEaid={setCohortEaid}
                    />
                  )}
                  {currentStep === 5 && isTSS && !isCCPlan && (
                    <TSSConfirmation />
                  )}
                  {((currentStep === 5 && !isTSS && !isCCPlan) ||
                    (currentStep === 4 && isCCPlan)) && (
                    <Commitment
                      prevStep={prevStep}
                      clientInitials={clientInitials}
                      updateInitials={updateInitials}
                      purchaseIntents={allSelectedPurchaseIntent}
                      onboardingCallDate={onboardingCallDate}
                      creativeCallDate={creativeCallDate}
                      clientEmail={null}
                    />
                  )}
                </StepsContainer>
              )}
            </>
          )}
        </WizardContainer>
      </MainContainer>
    </Container>
  )
}

export {UpgradeOrder}
