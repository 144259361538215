import React from 'react'
import {Icon, Radio} from '@thryvlabs/maverick'
import {toast} from 'react-toastify'

const DISCOUNT_TYPES = [
  {
    name: 'New Client 1 Month Free',
    value: 'New Client 1 Month Free',
    validForClients: ['new'],
  },
  {
    name: 'New Client Transition',
    value: 'New Client Transition',
    validForClients: ['new'],
  },
  {
    name: 'Existing Client',
    value: 'Existing Client',
    validForClients: ['existing'],
  },
]

function DiscountModal({close, discountType, setDiscountType, clientType}) {
  const handleChange = e => {
    setDiscountType(e.target.value)
    toast.success(`${e.target.value} Incentive Applied!`)
  }
  return (
    <div style={{width: '300px', height: '150px'}} data-testid="discount-modal">
      <Icon
        style={{cursor: 'pointer', float: 'right'}}
        onClick={close}
        type="regular"
        variant="x"
        height="18"
        width="18"
        color="#A3A5A7"
      />
      <div className="d-flex flex-column">
        <strong className="pb-3" style={{fontSize: '20px'}}>
          Apply Discount
        </strong>
        {DISCOUNT_TYPES.filter(discount =>
          discount.validForClients.includes(clientType),
        ).map(current => (
          <Radio
            key={current.name}
            hasLabel
            id={current.value}
            value={current.value}
            label={current.name}
            toggled={discountType === current.name}
            handleOnChange={handleChange}
          />
        ))}
      </div>
    </div>
  )
}

export {DiscountModal}
