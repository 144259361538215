import React, {useEffect, useState, useMemo} from 'react'
import {useLocation} from 'react-router'
import {CartDrawer} from './cc-sidebar-components/cart-drawer'
import checkIfIncentiveApplies from '../utils/check-if-incentive-applies'
import {ProductSection} from './cc-sidebar-components/product-section'
import {TransitionOverview} from './cc-sidebar-components/transition-overview'
import {SidebarContainer} from './ui/cc-sidebar'
import {BC_PLANS, MC_PLANS} from '../utils/constants'
import {formatPrice} from '../utils/currency-format'

const APPS_IN_CENTERS = [
  'Thryv Leads',
  'SEO Non-Guaranteed',
  'SEO Money Back Guarantee',
]

const FREE_ACCOUNT_CODES = [
  'appmarket-5-thryv-m2m',
  'cc_basicpls-5-thryv-m2m',
  'cc_basic-5-thryv-m2m',
  'addon-5-signatures_usage',
  'app-websitebuilder',
]

function PriceItem({title, amount}) {
  return (
    <div className="d-flex justify-content-between">
      <div className="d-flex">
        <p>{title}</p>
      </div>
      <p className="mr-2">{formatPrice(amount)}</p>
    </div>
  )
}

function CcSidebar({
  selectedCentersAndApps,
  incentiveCoupon,
  country,
  orderCouponCodes,
  eligibleTransitionCenters,
  defaultCentersAndApps,
  upgradePaymentPreview,
  previewRequestFinished,
  setCurrentPrice = () => {},
  setTotalCcPrice = () => {},
  isCohort,
  accountInfo,
}) {
  const [incentiveDiscount, setIncentiveDiscount] = useState(0)
  const [discount, setDiscount] = useState(0)
  const {pathname} = useLocation()

  const stringifiedEligibleTransitionCenters = JSON.stringify(
    eligibleTransitionCenters,
  )
  const isUpgrade = pathname === '/order/upgrade'

  const isFreeAccount = !(
    defaultCentersAndApps?.centers?.some(
      center => !FREE_ACCOUNT_CODES.includes(center.code),
    ) ||
    defaultCentersAndApps?.apps?.some(
      app => !FREE_ACCOUNT_CODES.includes(app.code),
    )
  )
  const isAus = country === 'AU'
  const isNZ = country === 'NZ'
  const isBCorMCInCart = selectedCentersAndApps.centers.some(
    ({code}) => BC_PLANS.includes(code) || MC_PLANS.includes(code),
  )
  const hasListingsApp = selectedCentersAndApps.apps.some(
    ({code}) => code === 'app-listings',
  )

  const selectedPlanCodes = useMemo(
    () => [
      ...selectedCentersAndApps.centers,
      ...selectedCentersAndApps.apps,
      ...selectedCentersAndApps.oneTimes,
    ],
    [
      selectedCentersAndApps.apps,
      selectedCentersAndApps.centers,
      selectedCentersAndApps.oneTimes,
    ],
  )

  const getTodaySubtotal = isAusNZ => {
    if (!isUpgrade || !upgradePaymentPreview) {
      // add credit as in earlier case we are deducting the credit
      return totalPrice > 0
        ? totalPrice - (accountInfo?.balanceInfo?.amount || 0)
        : '0.00'
    }
    let {subtotal, tax} = upgradePaymentPreview
    // Removing discount for MC Pro Enhanced from subtotal
    if (
      selectedCentersAndApps.centers.some(
        center => center.code === 'mc_pro_enh-5-thryv-m2m',
      )
    ) {
      const discount = upgradePaymentPreview.centers.find(
        center => center.planCode === 'mc_pro_enh-5-thryv-m2m',
      )?.discount
      if (discount) subtotal -= discount
    }
    return !isAusNZ ? subtotal : subtotal + tax
  }

  const renderTaxContent = () => {
    const isSEOPreviewAvailable = upgradePaymentPreview?.apps?.some(
      ({planCode}) =>
        ['seo-5-thryv-m2m', 'seombg-5-thryv-m2m'].includes(planCode),
    )
    if (isAus) {
      return (
        <div className="mt-2 text-right font-bold">
          <p>+ Applicable Fees</p>
          <p>*All Plans include GST {!isSEOPreviewAvailable && 'except SEO'}</p>
        </div>
      )
    }
    if (!isNZ && !upgradePaymentPreview) {
      return (
        <div className="text-right">
          <p>+ Applicable Taxes</p>
        </div>
      )
    }
  }

  const calcItemsTotal = items => {
    return items?.centers
      .concat(items?.apps, items?.oneTimes)
      .map(item => ({
        ...item,
        amount: item?.amount ? item.amount : 0,
      }))
      .reduce((acc, item) => {
        const isInvalidQuantity = APPS_IN_CENTERS.includes(item.name)
        if (isInvalidQuantity) return acc + item.amount
        return acc + item.amount * (item.quantity || 1)
      }, 0)
  }

  const filteredApps = selectedCentersAndApps.apps.filter(({code}) => {
    const isListingsApp = code === 'app-listings'
    if (isListingsApp) return !isBCorMCInCart
    return true
  })
  let totalPrice =
    calcItemsTotal({...selectedCentersAndApps, apps: filteredApps}) -
    (discount + incentiveDiscount) +
    (accountInfo?.balanceInfo?.amount || 0)
  if (totalPrice < 0) {
    totalPrice = 0
  }
  const oldPlanTotalPrice = calcItemsTotal(defaultCentersAndApps)

  useEffect(() => {
    console.log(totalPrice)
    setCurrentPrice(oldPlanTotalPrice)
    setTotalCcPrice(totalPrice)
  }, [
    totalPrice,
    oldPlanTotalPrice,
    selectedCentersAndApps.centers,
    defaultCentersAndApps?.centers,
    setCurrentPrice,
    setTotalCcPrice,
  ])

  useEffect(() => {
    let incentiveAmount = 0
    let discountAmount = 0

    selectedCentersAndApps.centers.forEach(currentCenter => {
      const showIncentive = checkIfIncentiveApplies(
        currentCenter,
        isUpgrade,
        eligibleTransitionCenters,
        incentiveCoupon,
      )

      if (showIncentive && incentiveCoupon?.code === 'transition75') {
        incentiveAmount += currentCenter.amount * 0.75
      } else if (showIncentive && incentiveCoupon?.code === 'nci') {
        incentiveAmount += currentCenter.amount
      }
    })

    orderCouponCodes.forEach(coupon => {
      let couponDiscountAmount = 0

      if (coupon.discountAmount) {
        couponDiscountAmount += coupon.discountAmount
      } else if (coupon.discountPercent && coupon.appliesToAllPlans) {
        selectedPlanCodes.forEach(plan => {
          if (!plan?.code.includes('setupfee')) {
            couponDiscountAmount += plan.amount * (coupon.discountPercent / 100)
          }
        })
      } else {
        selectedPlanCodes.forEach(plan => {
          if (
            coupon.code !== 'transition75' &&
            coupon.code !== 'nci' &&
            !coupon.appliesToPlan?.includes('mc_pro_enh-5-thryv-m2m') &&
            coupon?.appliesToPlans?.includes(plan?.code)
          ) {
            couponDiscountAmount += plan.amount * (coupon.discountPercent / 100)
          }
        })
      }

      discountAmount += couponDiscountAmount
    })

    setIncentiveDiscount(incentiveAmount)
    setDiscount(discountAmount)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCentersAndApps.centers,
    incentiveCoupon,
    orderCouponCodes,
    selectedPlanCodes,
    incentiveDiscount,
    discount,
    stringifiedEligibleTransitionCenters,
    isUpgrade,
  ])

  const newProductsSections = [
    {
      title: 'Centers',
      products: selectedCentersAndApps.centers.filter(
        currentCenter =>
          !APPS_IN_CENTERS.includes(currentCenter.name) &&
          currentCenter.code !== '247support-m2m',
      ),
      eligibleTransitionCenters,
      isUpgrade,
      incentiveCoupon,
      orderCouponCodes,
    },
    {
      title: 'Apps',
      products: [
        ...selectedCentersAndApps.apps,
        ...selectedCentersAndApps.centers.filter(({name}) =>
          APPS_IN_CENTERS.includes(name),
        ),
      ],
    },
    {
      title: 'One Time Fees',
      products: selectedCentersAndApps.oneTimes,
    },
  ]

  const oldProductsSections = isUpgrade && [
    {
      title: 'Centers',
      products: defaultCentersAndApps.centers.filter(
        currentCenter =>
          !APPS_IN_CENTERS.includes(currentCenter.name) &&
          currentCenter.code !== '247support-m2m',
      ),
    },
    {
      title: 'Apps',
      products: [
        ...defaultCentersAndApps.apps,
        ...defaultCentersAndApps.centers.filter(({name}) =>
          APPS_IN_CENTERS.includes(name),
        ),
      ],
    },
  ]

  const hasSupportFee = selectedCentersAndApps.centers.find(
    ({code}) => code === '247support-m2m',
  )
  const supportFeePreview = upgradePaymentPreview?.apps?.find(
    ({planCode}) => planCode === '247support-m2m',
  )
  const todayFinancialDetails = [
    {
      title: 'Support Fee',
      amount: supportFeePreview?.subtotal || hasSupportFee?.amount,
    },
    {
      title: 'Sub Total',
      amount: getTodaySubtotal(isAus || isNZ),
    },
    {
      title: 'Tax',
      amount: upgradePaymentPreview?.tax,
    },
    {
      title: 'Total',
      amount: Math.max(upgradePaymentPreview?.total || 0, 0),
    },
    {
      title: 'Credit',
      amount: accountInfo?.balanceInfo?.amount || 0,
    },
  ]

  return (
    <SidebarContainer>
      {isUpgrade && defaultCentersAndApps && (
        <CartDrawer title="Old Plan" totalPrice={oldPlanTotalPrice}>
          {oldProductsSections.map(section => (
            <ProductSection
              key={section.title + 'old'}
              country={country}
              isCohort={isCohort}
              {...section}
              isOld
            />
          ))}
          <div style={{lineHeight: '15px'}}>
            <PriceItem title="Sub Total" amount={oldPlanTotalPrice} />
          </div>
        </CartDrawer>
      )}
      <CartDrawer
        title="Total Due Today"
        totalPrice={
          !upgradePaymentPreview
            ? totalPrice
            : upgradePaymentPreview.total +
                (accountInfo?.balanceInfo?.amount || 0) >
              0
            ? upgradePaymentPreview.total +
              (accountInfo?.balanceInfo?.amount || 0)
            : 0
        }
        defaultOpen
        loading={previewRequestFinished === false}
      >
        {newProductsSections.map(section => (
          <ProductSection
            key={section.title}
            upgradePaymentPreview={upgradePaymentPreview}
            country={country}
            {...section}
            selectedCentersAndApps={selectedCentersAndApps}
            isCohort={isCohort}
          />
        ))}
        <div style={{lineHeight: '15px'}} className="mt-3">
          {orderCouponCodes &&
            orderCouponCodes.map(coupon => {
              if (
                coupon.code === 'transition75' ||
                coupon.code === 'nci' ||
                coupon.appliesToPlans?.includes('mc_pro_enh-5-thryv-m2m')
              ) {
                return <></>
              }
              let couponDiscountAmount = 0
              if (coupon.discountAmount) {
                couponDiscountAmount += coupon.discountAmount
              } else if (coupon.discountPercent && coupon.appliesToAllPlans) {
                selectedPlanCodes.forEach(plan => {
                  if (!plan?.code.includes('setupfee')) {
                    couponDiscountAmount +=
                      plan.amount * (coupon.discountPercent / 100)
                  }
                })
              } else {
                selectedPlanCodes.forEach(plan => {
                  if (
                    coupon?.appliesToPlans?.includes(plan?.code) &&
                    !plan?.code.includes('setupfee')
                  ) {
                    couponDiscountAmount +=
                      plan.amount * (coupon.discountPercent / 100)
                  }
                })
              }
              if (couponDiscountAmount > 0) {
                return (
                  <div
                    key={coupon.code}
                    className="d-flex justify-content-between"
                  >
                    <div className="d-flex">
                      <p>{coupon.description}</p>
                    </div>
                    <p className="mr-2">-{formatPrice(couponDiscountAmount)}</p>
                  </div>
                )
              }
              return <></>
            })}
          {todayFinancialDetails
            .filter(({title}) => {
              if (title === 'Support Fee') {
                return (
                  supportFeePreview || (hasSupportFee && !upgradePaymentPreview)
                )
              }
              if (!upgradePaymentPreview)
                return !['Tax', 'Total'].includes(title)
              if (isAus || isNZ) return title !== 'Tax'

              return true
            })
            .map(({title, amount}) => (
              <PriceItem key={title} title={title} amount={amount} />
            ))}
          {renderTaxContent()}
        </div>
      </CartDrawer>
      {!isFreeAccount && (
        <p className="m-3 ml-4">
          * The amount due today is an estimate and actual charge will be a
          prorated amount depending on when you upgrade and what you select.
        </p>
      )}
      {hasListingsApp && isBCorMCInCart && (
        <p className="m-3 ml-4">
          * The remaining prorated balance for the{' '}
          <span className="font-bold">Listings Management</span> will be applied
          to the account.
        </p>
      )}
      {incentiveCoupon?.code === 'transition75' && (
        <TransitionOverview
          selectedCentersAndApps={selectedCentersAndApps}
          eligibleTransitionCenters={eligibleTransitionCenters}
          isUpgrade={isUpgrade}
          country={country}
          upgradePaymentPreview={upgradePaymentPreview}
          accountInfo={accountInfo}
        />
      )}
    </SidebarContainer>
  )
}

export {CcSidebar}
