import {useState, useEffect} from 'react'
import {useAuthClient} from './use-auth-client'
import {useLocation} from 'react-router'
import {CC_PLANS} from './constants'
import {ADD_ON} from './addons-data'

const WIN_BACK_4_COUPONS = {
  'plus-4-dexyp-m2m': 'pluswinback2022',
  'unlimited-4-dexyp-m2m': 'unlimitedwinback2022',
  'professional-4-dexyp-m2m': 'professionalwinback2022',
}

const DISCOUNT_COUPONS_BY_TYPE = {
  'New Client Transition': 'transition75',
  'New Client 1 Month Free': 'nci',
}

function useAutoCoupons({
  discountType,
  selectedPackage,
  selectedIndustryId,
  selectedCountry,
  selectedCentersAndApps,
  eligibleTransitionCenters,
  selectedAddons,
  upgradeAccountInfo,
  incentiveCoupon,
  setIncentiveCoupon,
  currentStep,
  setOrderCouponCodes,
  ccCouponInfo,
  upgradePaymentPreview,
  isEaidFound,
  isCohort,
}) {
  const [autoCouponInfo, setAutoCouponInfo] = useState([])
  const [appliedCoupons, setAppliedCoupons] = useState(false)
  const client = useAuthClient()
  const {pathname} = useLocation()

  const isNewOrder =
    pathname === '/order/new' &&
    selectedIndustryId !== 13 &&
    selectedIndustryId !== 12
  const isUpgrade = pathname === '/order/upgrade'
  const selectedPlan = selectedPackage || 'appmarket-5-thryv-m2m'
  const is5Plan = selectedPlan.includes('-5-')

  const findSensisSiteDomain = () => {
    if (selectedCentersAndApps) {
      return selectedCentersAndApps.apps.find(app => app.sensisSiteDomain)
        ?.sensisSiteDomain
    }
    return selectedAddons?.find(
      x => x.product === ADD_ON.add_ons['Website'].label,
    )?.sensisSiteDomain
  }

  const resetAutoCoupons = () => {
    setAutoCouponInfo([])
    setAppliedCoupons(false)
  }

  useEffect(() => {
    if (
      (currentStep < 6 && !isUpgrade) ||
      (isUpgrade &&
        ((currentStep < 2 && is5Plan) || (currentStep < 3 && !is5Plan)))
    )
      return

    async function fetchCoupon({couponCode, planCode, addonsString}) {
      const baseUrl = 'orders/coupon/validate'
      const addonsQueryString = addonsString
        ? `addonsString=${addonsString}&`
        : ''
      const url = `${baseUrl}?${addonsQueryString}couponCode=${couponCode}&country=${selectedCountry.isoValue}&planCode=${planCode}`
      const coupon = await client(url)
      return coupon
    }

    async function applyAutoCoupons() {
      const isCcPlan = selectedCentersAndApps?.centers.find(({name}) =>
        CC_PLANS.some(plan => name.toLowerCase().includes(plan)),
      )

      const isWebsiteApp =
        selectedCentersAndApps?.apps.find(
          ({name}) => name === 'Professionally Designed Website',
        ) || selectedAddons?.find(({product}) => product === 'Website')
      const websiteEntitlement =
        selectedCentersAndApps?.centers
          .flatMap(obj => obj.entitlements || [])
          .find(entitlement =>
            ['ent-5-website', 'addon-5-website_difm'].includes(
              entitlement.code,
            ),
          ) || null
      const isTrialPlan = upgradeAccountInfo?.planCode?.includes('trial')
      const sensisDomain = findSensisSiteDomain()
      const addonsString =
        (!selectedCentersAndApps
          ? selectedAddons
              ?.filter(addon => addon.planCode !== undefined)
              ?.map(addon => addon.planCode)
          : [
              ...selectedCentersAndApps.centers,
              ...selectedCentersAndApps.apps,
              ...selectedCentersAndApps.oneTimes,
            ]
              .map(obj => obj.code)
              .join(',')) || ''
      let incentive = incentiveCoupon
      let data

      if (isNewOrder) {
        const discountCoupon = DISCOUNT_COUPONS_BY_TYPE[discountType]
        if (discountCoupon && isCcPlan) {
          data = {couponCode: discountCoupon, planCode: isCcPlan.code}
          incentive = true
        }
      } else if (eligibleTransitionCenters?.length > 0) {
        const transitionCenter = selectedCentersAndApps.centers.find(({name}) =>
          name.toLowerCase().includes(eligibleTransitionCenters[0]),
        )
        const discountCoupon = DISCOUNT_COUPONS_BY_TYPE[discountType]

        let transitionApplies = false
        if (discountCoupon) {
          if (!upgradePaymentPreview) transitionApplies = true
          if (discountCoupon === 'transition75' && upgradePaymentPreview) {
            const discountedCenter = upgradePaymentPreview.centers.find(
              ({planCode, discount}) =>
                planCode === transitionCenter.code && discount,
            )
            if (discountedCenter) transitionApplies = true
          }
        }
        if (transitionApplies) {
          data = {couponCode: discountCoupon, planCode: transitionCenter.code}
          incentive = true
        }
      } else if (!isNewOrder && upgradeAccountInfo.status !== 'active') {
        const winBackCoupon = WIN_BACK_4_COUPONS[selectedPlan]
        if (winBackCoupon)
          data = {couponCode: winBackCoupon, planCode: selectedPlan}
      } else if (isTrialPlan) {
        data = {
          couponCode: 'transition75',
          planCode: selectedPlan,
        }
      }

      let domainData

      if ((isWebsiteApp?.name || isWebsiteApp?.product) && sensisDomain) {
        domainData = {
          couponCode: 'urldbdesignfee',
          planCode: selectedPlan,
        }
      }
      if (websiteEntitlement && websiteEntitlement?.sensisSiteDomain) {
        domainData = {
          couponCode: 'urldbdesignfee',
          planCode: selectedPlan,
        }
      }

      const couponInfo = data && (await fetchCoupon({...data, addonsString}))
      const domainCoupon =
        domainData && (await fetchCoupon({...domainData, addonsString}))

      const cohortCouponApplies =
        isCohort &&
        (selectedCentersAndApps?.oneTimes.find(
          fee => fee.code === 'thryvprfee',
        ) ||
          selectedAddons?.find(addon => addon.planCode === 'onboarding-fee'))

      const cohortCoupon =
        cohortCouponApplies &&
        (await fetchCoupon({
          couponCode: 'cohort-waive-onboardingfee',
          addonsString,
        }))

      let autoCouponData = []
      if (incentive) setIncentiveCoupon(couponInfo)

      if (couponInfo) autoCouponData.push(couponInfo)
      if (domainCoupon) autoCouponData.push(domainCoupon)
      if (cohortCoupon) autoCouponData.push(cohortCoupon)

      if (autoCouponData.length > 0) setAutoCouponInfo(autoCouponData)
    }
    applyAutoCoupons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, upgradePaymentPreview])

  useEffect(() => {
    if (
      ((currentStep < 6 && !isUpgrade) ||
        (isUpgrade && ((currentStep < 2 && is5Plan) || !is5Plan))) &&
      appliedCoupons
    )
      return
    setOrderCouponCodes(
      [...autoCouponInfo, ccCouponInfo?.isValid ? ccCouponInfo : null].filter(
        Boolean,
      ),
    )
    setAppliedCoupons(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCouponInfo,
    ccCouponInfo,
    setOrderCouponCodes,
    currentStep,
    appliedCoupons,
  ])

  return {autoCouponInfo, resetAutoCoupons}
}

export default useAutoCoupons
