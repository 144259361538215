import React, {useRef} from 'react'
import {CartContainer} from '../ui/cartProducts'
import {ParagraphText} from '@thryvlabs/maverick'
import {TRANSITION_INCENTIVES} from '../../utils/constants'
import getPreviewItemTotal from '../../utils/get-preview-item-total'
import {formatPrice} from '../../utils/currency-format'

function TransitionItem({title, subtitle, amount, country}) {
  if (!amount) return null
  return (
    <li
      style={{
        background: 'white',
        border: '1px solid rgba(0, 0, 0, 0.3)',
        marginBottom: '10px',
        height: '40px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div className="ml-2">
        {title}{' '}
        {subtitle && (
          <span style={{color: 'rgba(163,165,167)'}}>{subtitle}</span>
        )}
      </div>
      <div className="mr-2">
        {formatPrice(amount)}
        {!['AU', 'NZ'].includes(country) && ' + Tax'}
      </div>
    </li>
  )
}

function TransitionOverview({
  selectedCentersAndApps,
  eligibleTransitionCenters,
  isUpgrade,
  country,
  upgradePaymentPreview,
  accountInfo,
}) {
  const incentiveUpgradeIndex = useRef(0)

  const monthlyItems = selectedCentersAndApps.centers.concat(
    selectedCentersAndApps.apps,
  )
  const isAus = country === 'AU'

  const calcTotal = (arr, discountPercent, index) => {
    console.log({arr, index, discountPercent})
    return arr.reduce((acc, {name, amount}) => {
      // const transitionApplies = (
      //   !isUpgrade ? CC_PLANS : eligibleTransitionCenters
      // ).some(plan => name.toLowerCase().includes(plan))

      let price = amount ? parseFloat(amount) : 0

      if (upgradePaymentPreview) {
        const previewAmount = getPreviewItemTotal({
          upgradePaymentPreview,
          item: {name, price},
          country,
        })
        if (previewAmount) price = previewAmount
      }
      /////////////////////////////////////////////
      // this logic only provide the discout on newly added logic and ignoring the discount to previous added product

      // if (transitionApplies && discountPercent) {
      //   price = price - price * discountPercent
      // }
      ///////////////////////////////////////////////

      if (discountPercent && name.includes('Center')) {
        price = price - price * discountPercent
      }
      return acc + price
    }, 0)
  }

  const calculateIncentiveAmount = index => {
    const transitionDiscount = TRANSITION_INCENTIVES[index].discount
    const monthlyPrice = calcTotal(monthlyItems, transitionDiscount, index)

    if (upgradePaymentPreview) {
      const {centers, apps} = upgradePaymentPreview
      const {discount: itemDiscount, subtotal: itemSubtotal} =
        centers?.find(({discount}) => discount) ?? {}
      let monthlySubtotal = [...centers, ...apps].reduce(
        (acc, {subtotal, tax}) => acc + (!isAus ? subtotal : subtotal + tax),
        0,
      )
      if (transitionDiscount === 0.75) {
        monthlySubtotal =
          monthlySubtotal + (accountInfo?.balanceInfo?.amount || 0)
        monthlySubtotal = Math.max(monthlySubtotal, 0)
      }
      const totalDiscount = centers.reduce(
        (acc, {discount}) => acc + discount,
        0,
      )

      const itemDiscountPercentage = +(itemDiscount / itemSubtotal).toFixed(2)
      if (itemDiscountPercentage === transitionDiscount) {
        incentiveUpgradeIndex.current = index
        return monthlySubtotal - totalDiscount
      }
      if (index < incentiveUpgradeIndex.current) return null
    }

    return formatPrice(Math.max(monthlyPrice, 0))
  }
  return (
    <CartContainer
      style={{
        padding: '10px',
        width: '100%',
        marginLeft: '20px',
        marginTop: '20px',
        background: 'rgba(163,165,167, 0.1)',
      }}
    >
      <ParagraphText variant="lg">
        <strong> Monthly Overview </strong>
      </ParagraphText>
      <ParagraphText style={{lineHeight: '5px'}} variant="reg">
        <strong>Transition Incentive</strong>
      </ParagraphText>
      {TRANSITION_INCENTIVES.map(({title, subtitle}, index) => (
        <TransitionItem
          key={title}
          title={title}
          subtitle={subtitle}
          country={country}
          amount={calculateIncentiveAmount(index)}
        />
      ))}
    </CartContainer>
  )
}

export {TransitionOverview}
