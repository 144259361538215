import React from 'react'
import {CallModalContainer} from '../ui/onboarding-program'
import {ParagraphText} from '@thryvlabs/maverick'
import ConfirmedCall from './confirmed-call'
import UnconfirmedCall from './unconfirmed-call'

export default function CallSection({
  apptData,
  selectedCentersAndApps,
  isUpgrade,
  isSyncUpgrade,
  isLoading,
  shouldEnableCreativeCallModal,
  includeAddonsCodes,
  industryCategoryType,
  isRestrictedAccessHIPPASelected,
  noneCustomVideoCreativeItem,
  isMCSelected,
  isUsSpanishOptionAvailable,
  accountInformation,
  register,
  errors,
  isThryvLeadsOrSeoIncluded,
  defaultDate,
  onboardingCallDate,
}) {
  return (
    <CallModalContainer isCC={selectedCentersAndApps}>
      {!selectedCentersAndApps && apptData.callType === 'creative' && <hr />}
      <ParagraphText
        variant="lg"
        color={
          apptData.callConfirmed && selectedCentersAndApps
            ? ''
            : 'thryv-gray-medium-500'
        }
        style={{
          fontWeight:
            apptData.callConfirmed && selectedCentersAndApps ? '600' : 'normal',
          width: '100%',
        }}
      >
        Thryv {apptData.callType === 'creative' ? 'Creative' : 'Kickoff'} Call{' '}
        {apptData.languagePreference.value && selectedCentersAndApps && (
          <span>
            <strong> (Spanish)</strong>
          </span>
        )}{' '}
        {isUpgrade &&
          !isSyncUpgrade &&
          !selectedCentersAndApps &&
          apptData.callType === 'kickoff' &&
          !apptData.isOnboardingRequired4Upgrade && (
            <span>
              <strong>(Optional)</strong>
            </span>
          )}
      </ParagraphText>
      {apptData.callConfirmed || apptData.isApptSkipped ? (
        <ConfirmedCall
          callType={apptData.callType}
          callDate={apptData.callDate}
          isApptSkipped={apptData.isApptSkipped}
          selectedCentersAndApps={selectedCentersAndApps}
          cancelAppointment={apptData.cancelAppointment}
          selectedService={apptData.selectedService}
          apptId={apptData.apptId}
        />
      ) : (
        <UnconfirmedCall
          apptData={apptData}
          selectedCentersAndApps={selectedCentersAndApps}
          isLoading={isLoading}
          shouldEnableCreativeCallModal={shouldEnableCreativeCallModal}
          includeAddonsCodes={includeAddonsCodes}
          industryCategoryType={industryCategoryType}
          isRestrictedAccessHIPPASelected={isRestrictedAccessHIPPASelected}
          noneCustomVideoCreativeItem={noneCustomVideoCreativeItem}
          isMCSelected={isMCSelected}
          isUsSpanishOptionAvailable={isUsSpanishOptionAvailable}
          accountInformation={accountInformation}
          selectedService={apptData.selectedService}
          register={register}
          errors={errors}
          apptId={apptData.apptId}
          isThryvLeadsOrSeoIncluded={isThryvLeadsOrSeoIncluded}
          defaultDate={defaultDate}
          onboardingCallDate={onboardingCallDate}
        />
      )}
    </CallModalContainer>
  )
}
